import { getLocalStorageItem } from "../../helpers/localStorage";
import { auth_constants } from "../actions/constants";

const initState = {
  token: getLocalStorageItem("token"),
  user: getLocalStorageItem("user") != null
    ? JSON.parse(getLocalStorageItem("user") ?? "")
    : {
      _id: "",
      first_name: "",
      role: "user",
      last_name: "",
      phone_number: "",
      email: "",
      activated: false,
    },
  authenticate: getLocalStorageItem("token") != null ? true : false,
  authenticating: false,
  loading: false,
  error: null,
  message: "",
};

console.log("initState", initState);

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case auth_constants.PROFILE_REQUEST:
      state = {
        ...state,
        loading: true,
        authenticating: true,
        error: null,
      };
      break;
    case auth_constants.PROFILE_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        authenticate: true,
        loading: false,
        authenticating: false,
      };
      break;
    case auth_constants.PROFILE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
        authenticating: false,
      };
      break;
    case auth_constants.LOGOUT_SUCCESS:
      state = {
        ...initState,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default authReducer;
