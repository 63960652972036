import React, { useEffect, useState } from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import { LockIcon } from "../Icons/LockIcon.jsx";
import { Diamond } from "../Icons/Diamond.jsx";
import { EditIcon } from "../Icons/EditIcon.jsx";

import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { Profile } from "../Icons/Profile.jsx";
import { VoiceIcon } from "../Icons/VoiceIcon.jsx";
import { contentHistory } from "../api/user.api.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function History() {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    contentHistory(auth.user._id)
      .then((res) => {
        console.log(res.data);
        setVideoList(res.data.contentData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <section className="overflow-hidden">
        <div className="container login-banner pt-5  mx-auto flex   flex-col   gap-26  border-top-grey">
          <div className="flex gap-4 items-center  ">
            <img src="/images/videoicon.svg"></img>
            <p className="text-[34px] text-[#fff]">My Videos</p>
          </div>
          {videoList.map((videoData) => (
            <div className="py-4 px-5 bg-[#1F1F1F] rounded-3xl">
              <div className="md:flex justify-between">
                <div className="flex flex-col gap-5">
                  <p className="text-[#A1A1A1] text-[18px]">Title</p>
                  <p className="p-4 bg-[grey] text-[#FFFFFF] rounded-full history-video-title">
                    {videoData.title}
                  </p>
                  <p
                    className="flex gap-3 text-[#fff]"
                    onClick={(e) =>
                      navigate(`/video/generated/${videoData._id}`)
                    }
                  >
                    <span>View Full Details</span>
                    <img
                      src="/images/backicon.svg"
                      className="rotate-180"
                    ></img>
                  </p>
                </div>
                <div className="border-l border-l-2 border-l-[#3D3D3D] px-4 md:flex gap-4 justify-around mt-2 md:mt-0">
                  <div className="flex mb-3 md:mb-0 md:flex-col justify-center items-center gap-4">
                    <div className="border border-[#fff] p-2 flex items-center justify-center   rounded-full w-[54px] h-[54px]">
                      <img src="/images/share.svg"></img>
                    </div>
                    <div className="border border-[#3D3D3D]  rounded-full w-[54px] h-[54px]">
                      <Dropdown>
                        <DropdownTrigger>
                          <Button className="border border-[#fff ] p-1 flex items-center justify-center bg-transparent  rounded-full w-[54px] h-[54px] min-w-[54px]">
                            <img src="/images/download.svg"></img>
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          className="download-video-dropdown"
                          aria-label="Static Actions"
                        >
                          <DropdownItem className="first-item" key="">
                            Download Video
                          </DropdownItem>
                          <DropdownItem key="copy">
                            Download in
                            <span className="highlighted-text"> 4k</span>
                          </DropdownItem>
                          <DropdownItem key="edit">
                            Download in
                            <span className="highlighted-text"> 1080p</span>
                          </DropdownItem>
                          <DropdownItem key="edit">
                            Download in
                            <span className="highlighted-text"> 720p</span>
                          </DropdownItem>
                          <DropdownItem key="edit">
                            Download in
                            <span className="highlighted-text"> 480p</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className=" rounded-lg">
                    {/* <iframe src={videoData?.video_url}></iframe> */}
                    <video
                      className="my-videos-video"
                      src={videoData?.video_url}
                      controls={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
