import React from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import { LockIcon } from "../Icons/LockIcon.jsx";

import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  useDisclosure,
  Card,
  CardBody,
  CardFooter,
  Image,
} from "@nextui-org/react";

export default function DemoModal({ isOpen, onClose }) {
  const { onOpen, onOpenChange } = useDisclosure();
  const [size, setSize] = React.useState("md");
  const handleOpen = (size) => {
    setSize("4xl");
    onOpen();
  };

  const list = [
    {
      title: "Orange",
      img: "/images/fruit-1.jpeg",
      price: "$5.50",
    },
    {
      title: "Tangerine",
      img: "/images/fruit-2.jpeg",
      price: "$3.00",
    },
    {
      title: "Raspberry",
      img: "/images/fruit-3.jpeg",
      price: "$10.00",
    },
    {
      title: "Lemon",
      img: "/images/fruit-4.jpeg",
      price: "$5.30",
    },
    {
      title: "Avocado",
      img: "/images/fruit-5.jpeg",
      price: "$15.70",
    },
    {
      title: "Lemon 2",
      img: "/images/fruit-6.jpeg",
      price: "$8.00",
    },
  ];

  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="4xl"
      onClose={onClose}
      placement="top-center"
    >
      <ModalContent>
        <>
          <ModalHeader className="flex  text-white flex-col text-center gap-1">
            <h3 className="modal-heading"> Demo Videos For You</h3>
          </ModalHeader>
          <ModalBody className="text-white">
            <div className="gap-2 grid grid-cols-2 sm:grid-cols-3">
              {list.map((item, index) => (
                <Card
                  className="border-[#A1A1A1] border"
                  shadow="sm"
                  key={index}
                  isPressable
                  onPress={() => console.log("item pressed")}
                >
                  <CardBody className="overflow-visible bg-[#0D0D0D] p-0">
                    <Image
                      shadow="sm"
                      radius="lg"
                      width="100%"
                      alt={item.title}
                      className="w-full object-cover h-[140px]"
                      src={item.img}
                    />
                  </CardBody>
                  <CardFooter className="text-small flex-col-reverse bg-[#0D0D0D] items-baseline justify-between">
                    <b className="text-white">{item.title}</b>
                    <p className="text-default-500  text-[#A1A1A1]">
                      {item.price}
                    </p>
                  </CardFooter>
                </Card>
              ))}
            </div>
          </ModalBody>
        </>
      </ModalContent>
    </Modal>
  );
}
