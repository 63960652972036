import React, { useState } from "react";
import { Input, useDisclosure, Textarea } from "@nextui-org/react";
import { haveValue } from "../helpers/common.js";
import { useNavigate } from "react-router-dom";

export default function TitleDescription({ isOpen, onClose }) {
  const { onOpen, onOpenChange } = useDisclosure();
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleGenerateScript = () => {
    if (!haveValue(formData?.title)) {
      setErrors((prev) => ({
        ...prev,
        title: "Title cannot be empty",
      }));
      return;
    }
    navigate(`/script`, {
      state: {
        title: formData?.title,
        description: formData?.description,
      },
    });
  };

  return (
    <section className="overflow-hidden">
      <div className="max-w-[680px] login-banner  mx-auto flex items-start flex-col justify-center  gap-12 ">
        <h1 className="main-heading">Add Title And Description</h1>
        <p className="main-description">
          Add title and description to generate a script for your video
        </p>

        <div className="mail-input form-field w-full">
          <Input
            className="text-white"
            classNames={{
              label: "text-white !important",
              inputWrapper: "h-12",
            }}
            style={{
              label: { color: "white !important" },
            }}
            radius="full"
            type="text"
            label="Title"
            placeholder="Enter Your Title"
            labelPlacement="outside"
            variant="bordered"
            name="title"
            onChange={handleInputChange}
          />
          {errors.title && <p>{errors.title}</p>}
        </div>
        <div className="mail-input form-field w-full max-w-full">
          <Textarea
            className=" w-full  add-desc-field"
            classNames={{
              inputWrapper: "bg-[#000] !important border rounded-[25px]",
            }}
            label="Description"
            labelPlacement="outside"
            placeholder="Enter your description"
            name="description"
            onChange={handleInputChange}
          />
        </div>
        <div className="flex justify-center w-full">
          <button
            className="startedbtn genrate-script bg-white text-[#000]"
            onClick={handleGenerateScript}
          >
            <span className="startedcontainer-1 bg-black">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M6 12H18M18 12L13 7M18 12L13 17"
                    stroke="#FFF"
                    stroke-width="0.648"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </span>

            <p className="text-black text-center text-[18px] font-normal leading-[1.4]">
              Generate Script
            </p>
          </button>
        </div>
      </div>
    </section>
  );
}
