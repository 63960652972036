import React from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import { LockIcon } from "../Icons/LockIcon.jsx";
import { Diamond } from "../Icons/Diamond.jsx";
import { EditIcon } from "../Icons/EditIcon.jsx";

import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Link,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
  Input,
  useDisclosure,
  Divider,
} from "@nextui-org/react";

export default function ProfileWithSubscription({ isOpen, onClose }) {
  const { onOpen, onOpenChange } = useDisclosure();
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="xl"
      placement="top-center"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center text-white flex-col text-center gap-1">
              <div className="profile-name-letter">RW</div>
              <h5 className="text-4xl"> Richard Willies</h5>
            </ModalHeader>
            <ModalBody className="text-white relative">
              {/* <Button
                color="warning"
                className="text-white font-bold rounded-3xl h-12 "
                startContent={<Diamond />}>
                Join Premium
              </Button> */}
              <div className="border flex justify-between py-4 px-6 rounded-full border-[#E5A605]">
                <p className="flex gap-4">
                  <Diamond></Diamond>
                  <span className="text-[18px] font-bold"> Premium Member</span>
                </p>
                <p className="text-[18px]">Valid Till : 10/31/2023</p>
              </div>
              <div className=" ">
                <div className="  bg-neutral-800 rounded-[40px] py-4  px-4 md:px-6">
                  <div className=" flex justify-between items-center py-2">
                    <div className="flex gap-4">
                      <img className="" alt="Vector" src="vector.svg" />
                      <div className="  font-normal text-[#d1d1d1] text-base tracking-[0] leading-[22.4px] whitespace-nowrap">
                        Phone Number
                      </div>
                    </div>
                    <div className="    font-normal text-[#ffffff] text-lg text-right tracking-[0] leading-[25.2px] whitespace-nowrap">
                      +1 1236547890
                    </div>
                  </div>
                  <div className=" flex justify-between items-center py-2">
                    <div className="flex gap-4">
                      <img className="" alt="Vector" src="vector.svg" />
                      <div className="  font-normal text-[#d1d1d1] text-base tracking-[0] leading-[22.4px] whitespace-nowrap">
                        Phone Number
                      </div>
                    </div>
                    <div className="font-normal text-[#ffffff] text-lg text-right tracking-[0] leading-[25.2px] whitespace-nowrap">
                      +1 1236547890
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="flex-col justify-self-center items-center">
              <Button
                color=""
                className="modal-submit-btn "
                startContent={<EditIcon />}
              >
                Edit Profiles
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
