import React, { useState } from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import { LockIcon } from "../Icons/LockIcon.jsx";

import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Link,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
  Input,
  useDisclosure,
  Divider,
} from "@nextui-org/react";
import Joi from "joi-browser";
import {
  validateJOIFormField,
  validateJOIProperty,
} from "../helpers/common.js";
import { changePass } from "../api/user.api.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Reset({ isOpen, onClose }) {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { onOpen, onOpenChange } = useDisclosure();
  const [isVisibleOld, setIsVisibleOld] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [isVisibleRe, setIsVisibleRe] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    password: "",
    old_password: "",
    confirm_password: "",
  });
  const schema = {
    password: Joi.string().required(),
    old_password: Joi.string().required(),
    confirm_password: Joi.string().required(),
  };

  const toggleVisibilityOld = () => setIsVisibleOld(!isVisibleOld);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const toggleVisibilityRe = () => setIsVisibleRe(!isVisibleRe);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const errorData = { ...errors };
    var errorMessage = validateJOIProperty(schema, e);
    if (errorMessage) {
      errorData[name] = errorMessage;
    } else {
      errorData[name] && delete errorData[name];
    }
    setErrors(errorData);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangePassword = () => {
    let error = validateJOIFormField(formData, schema);
    if (error) {
      setErrors(error);
      return;
    }
    if (formData?.password != formData?.confirm_password) {
      setErrors((prev) => ({
        ...prev,
        confirm_password: "Password does not match",
      }));
      return false;
    }
    const temp = {
      id: auth.user._id,
      ...formData,
    };
    changePass(temp)
      .then((res) => {
        onClose();
      })
      .catch((err) => {
        const errorMessage =
          err.response && err.response.data
            ? err.response.data.message
            : err.message;
        toast.error(errorMessage);
      });
  };

  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      onClose={onClose}
    >
      <ModalContent>
        <>
          <ModalHeader className="flex  text-white flex-col text-center gap-1">
            <h3 className="modal-heading"> Change Password</h3>
          </ModalHeader>
          <ModalBody className="text-white">
            <div className="password-input form-field">
              <Input
                endContent={
                  <button
                    className="focus:outline-none text-white border-solid border-white"
                    type="button"
                    classNames={{
                      label: "text-white",
                    }}
                    onClick={toggleVisibilityOld}
                    aria-label="toggle password visibility"
                  >
                    {isVisibleOld ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisibleOld ? "text" : "password"}
                radius="full"
                label="Old Password"
                placeholder="Enter your password"
                labelPlacement="outside"
                variant="bordered"
                name="old_password"
                onChange={handleInputChange}
              />
              {errors.old_password && (
                <p className="validation_error">{errors.old_password}</p>
              )}
            </div>
            <div className="password-input form-field">
              <Input
                endContent={
                  <button
                    className="focus:outline-none text-white border-solid border-white"
                    type="button"
                    classNames={{
                      label: "text-white",
                    }}
                    onClick={toggleVisibility}
                    aria-label="toggle password visibility"
                  >
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                radius="full"
                label="New Password"
                placeholder="Enter your password"
                labelPlacement="outside"
                variant="bordered"
                name="password"
                onChange={handleInputChange}
              />
              {errors.password && (
                <p className="validation_error">{errors.password}</p>
              )}
            </div>
            <div className="password-input form-field">
              <Input
                endContent={
                  <button
                    className="focus:outline-none text-white border-solid border-white"
                    type="button"
                    classNames={{
                      label: "text-white",
                    }}
                    onClick={toggleVisibilityRe}
                    aria-label="toggle password visibility"
                  >
                    {isVisibleRe ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisibleRe ? "text" : "password"}
                radius="full"
                label="Re-Type New Password"
                placeholder="Enter your password"
                labelPlacement="outside"
                variant="bordered"
                name="confirm_password"
                onChange={handleInputChange}
              />
              {errors.confirm_password && (
                <p className="validation_error">{errors.confirm_password}</p>
              )}
            </div>
            <Link
              href={undefined}
              size="sm"
              className="ml-auto forgot-link-underline"
              onClick={(e) => {
                navigate("/#forgot");
                onClose();
              }}
            >
              Forgot password?
            </Link>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center">
            <Button
              className="modal-submit-btn"
              color="primary"
              onPress={handleChangePassword}
            >
              Change Password
            </Button>
            {/* <p className="register-text mt-5  text-lg ">
              Already have an account?
              <span className="font-bold"> Login</span>
            </p> */}
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
