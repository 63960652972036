import React from "react";
export const Logout = (props) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.273 19.4952H6.136C4.84401 19.5548 3.58055 19.103 2.61925 18.2378C1.65795 17.3725 1.07621 16.1633 1 14.8722V5.62823C1.07621 4.33711 1.65795 3.12798 2.61925 2.2627C3.58055 1.39742 4.84401 0.945648 6.136 1.00523H11.272"
      stroke="#696969"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.591 10.2502H5.23804"
      stroke="#696969"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M13.8789 15.3863L18.2829 10.9823C18.4759 10.7875 18.5841 10.5244 18.5841 10.2503C18.5841 9.97609 18.4759 9.71301 18.2829 9.51826L13.8789 5.11426"
      stroke="#696969"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
