import axios from "axios";
import store from "../redux/store";
import { auth_constants } from "../redux/actions/constants";
const token = window.localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    Authorization: token || "",
  },
});

axiosInstance.interceptors.request.use((req) => {
  const { auth } = store.getState();
  if (auth.token) {
    req.headers.Authorization = `${auth.token}`;
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    console.log("internal", error);
    const status = error.response ? error.response.status : 401;
    if (status && status === 401) {
      localStorage.clear();
      window.location.reload();
      //Dispatch Logout Action
      store.dispatch({ type: auth_constants.LOGOUT_SUCCESS });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
