import React, { useEffect, useState } from "react";
import { MailIcon } from "../../Icons/MailIcon.jsx";
import { LockIcon } from "../../Icons/LockIcon.jsx";
import { Diamond } from "../../Icons/Diamond.jsx";
import { EditIcon } from "../../Icons/EditIcon.jsx";

import { EyeFilledIcon } from "../../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../../Icons/EyeSlashFilledIcon.jsx";

import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { Profile } from "../../Icons/Profile.jsx";
import { VoiceIcon } from "../../Icons/VoiceIcon.jsx";
import { contentHistory } from "../../api/user.api.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { camelToTitleCase, initialPaginationData, statusBadgeClass } from "../../helpers/common.js";
import Pagination from "../../components/Common/Pagination.js";

export default function History() {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [isLoading, setIsLoading] = useState(true);

  const loadData = (showLoading = true) => {
    if (showLoading) {
      setIsLoading(true);
    }

    const query = {
      page,
      limit: 10
    };

    contentHistory(query)
      .then((res) => {
        const content = res?.data;
        setPaginationData(content);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });

  };

  const handlePageChange = (newPageNo) => {
    setPage(newPageNo);
  };

  useEffect(() => {
    loadData();
  }, [page]);

  return (
    <>
      <section className="overflow-hidden mb-5">
        <div className="container login-banner pt-5  mx-auto flex   flex-col   gap-26  border-top-grey">
          <div className="flex gap-4 items-center  ">
            <img src="/images/videoicon.svg"></img>
            <p className="text-[34px] text-[#fff]">My Videos</p>
          </div>
          <div className="grid-content-wrapper">
            <div className="grid-content">
              {
                isLoading ? <>
                  <div className="flex w-full flex-col gap-4">
                    <div className="skeleton h-32 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                    <div className="skeleton h-4 w-full"></div>
                  </div>
                </> : <>
                  {
                    paginationData?.docs && paginationData?.docs.length > 0 ? <>
                      {paginationData?.docs.map((videoData) => (
                        <div className="py-4 mb-5 px-5 bg-[#1F1F1F] rounded-3xl">
                          <div className="md:flex justify-between">
                            <div className="flex flex-col gap-5">
                              <p className="text-[#A1A1A1] text-[18px]">
                                <span className={statusBadgeClass(videoData?.status)}>{camelToTitleCase(videoData?.status)}</span>
                              </p>
                              <p className="p-4 bg-[grey] text-[#FFFFFF] rounded-full history-video-title">
                                {videoData.title}
                              </p>
                              <p
                                className="flex gap-3 text-[#fff]"
                                onClick={(e) =>
                                  navigate(`/video/${videoData._id}`)
                                }
                              >
                                <span>View Full Details</span>
                                <img
                                  src="/images/backicon.svg"
                                  className="rotate-180"
                                ></img>
                              </p>
                            </div>
                            <div className="border-l border-l-2 border-l-[#3D3D3D] px-4 md:flex gap-4 justify-around mt-2 md:mt-0">
                              <div className="flex mb-3 md:mb-0 md:flex-col justify-center items-center gap-4">
                                <div className="border border-[#fff] p-2 flex items-center justify-center   rounded-full w-[54px] h-[54px]">
                                  <img src="/images/share.svg"></img>
                                </div>
                                <div className="border border-[#3D3D3D]  rounded-full w-[54px] h-[54px]">
                                  <Dropdown>
                                    <DropdownTrigger>
                                      <Button className="border border-[#fff ] p-1 flex items-center justify-center bg-transparent  rounded-full w-[54px] h-[54px] min-w-[54px]">
                                        <img src="/images/download.svg"></img>
                                      </Button>
                                    </DropdownTrigger>
                                    <DropdownMenu
                                      className="download-video-dropdown"
                                      aria-label="Static Actions"
                                    >
                                      <DropdownItem className="first-item" key="">
                                        Download Video
                                      </DropdownItem>
                                      <DropdownItem key="copy">
                                        Download in
                                        <span className="highlighted-text"> 4k</span>
                                      </DropdownItem>
                                      <DropdownItem key="edit">
                                        Download in
                                        <span className="highlighted-text"> 1080p</span>
                                      </DropdownItem>
                                      <DropdownItem key="edit">
                                        Download in
                                        <span className="highlighted-text"> 720p</span>
                                      </DropdownItem>
                                      <DropdownItem key="edit">
                                        Download in
                                        <span className="highlighted-text"> 480p</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </div>
                              </div>
                              <div className=" rounded-lg">
                                {/* <iframe src={videoData?.videoFileUrl}></iframe> */}
                                <video
                                  className="my-videos-video"
                                  src={videoData?.videoFileUrl}
                                  controls={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </> : <>
                      <div className="text-center p-5 text-white">No Videos Found</div>
                    </>
                  }
                </>
              }
            </div>
            {paginationData.totalDocs > 0 ? (
              <Pagination
                onPageChange={handlePageChange}
                paginationData={paginationData}
              />
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
}
