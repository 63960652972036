import * as Joi from "joi-browser";
import axiosInstance from "./axios";

export const haveValue = (val) => {
  if (
    val !== "" &&
    val !== "undefined" &&
    val !== undefined &&
    val !== null &&
    val !== "null"
  ) {
    return true;
  } else {
    return false;
  }
};

export const validateJOIFormField = (formFields, schema) => {
  const result = Joi.validate(formFields, schema, {
    abortEarly: false,
    stripUnknown: true,
  });
  const { error } = result;
  if (!error) {
    return null;
  } else {
    const errorData = {};
    for (let item of error.details) {
      const name = item.path[0];
      errorData[name] = item.message;
    }
    return errorData;
  }
};

export const validateJOIProperty = (schema, event) => {
  const { name, value } = event.target;
  const obj = { [name]: value };
  if (schema.hasOwnProperty(name)) {
    const subSchema = { [name]: schema[name] };
    const result = Joi.validate(obj, subSchema, {
      stripUnknown: true,
    });
    const { error } = result;
    return error ? error.details[0].message : null;
  } else {
    return null;
  }
};

export const getToken = () => {
  return localStorage.getItem("token") || null;
};

export const checkToken = () => {
  let token = getToken();
  if (token) {
    return true;
  }
  return false;
};

export const ApiRequest = async (url, method = "POST", payload = {}) => {
  try {
    const options = {
      url,
      method,
    };

    if (method === "POST") {
      options.data = payload;
    } else if (method === "GET") {
      options.params = payload;
    }
    
    const res = await axiosInstance(options);
    return res.data;
  } catch (error) {
    throw error;
  }
};
