import React, { useEffect, useState } from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import { LockIcon } from "../Icons/LockIcon.jsx";
import OTPInput, { ResendOTP } from "otp-input-react";
import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Link,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
  Input,
  useDisclosure,
  Divider,
} from "@nextui-org/react";
import { resendOtp, verifyOTP } from "../api/user.api.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isUserLoggedIn } from "../redux/actions/auth.actions.js";
import { useLocation, useNavigate } from "react-router-dom";

export default function EnterOtp({ isOpen, onClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const { onOpen, onOpenChange } = useDisclosure();
  const [showTimer, setShowTimer] = useState(60);
  const [showResend, setShowResend] = useState(false);
  const [OTP, setOTP] = useState("");
  useEffect(() => {
    setShowTimer(60);
    setOTP("");
  }, []);

  useEffect(() => {
    if (OTP.length == 6) {
      const user = JSON.parse(localStorage.getItem("user"));
      const temp = {
        otp: OTP,
        email: user.email,
      };
      verifyOTP(temp)
        .then((res) => {
          console.log(res);
          if (res.status == "success") {
            if (location.hash == "#forgotPassword") {
              navigate("/#resetPass");
            } else {
              const user = res.data.user;
              const userTemp = {
                _id: user._id,
                first_name: user.first_name,
                last_name: user.last_name,
                phone_number: user.phone_number,
                email: user.email,
                activated: user.activated,
              };
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("user", JSON.stringify(userTemp));
              isUserLoggedIn();
              onClose();
            }
          }
        })
        .catch((err) => {
          const errorMessage =
            err.response && err.response.data
              ? err.response.data.message
              : err.message;
          toast.error(errorMessage);
        });
    }
  }, [OTP]);

  useEffect(() => {
    if (showTimer === 0) {
      console.log("ADDING STATE NOW");
      setShowResend(true);
      return;
    } // Stop the timer when it reaches 0

    const timerId = setInterval(() => {
      setShowTimer((prevTime) => prevTime - 1);
    }, 1000); // Decrease time every second

    return () => clearInterval(timerId); // Cleanup the timer when the component unmounts
  }, [showTimer]);

  const otpinputChange = (e) => {
    setOTP(e);
  };

  const resendOTP = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    resendOtp({ email: user?.email });
    setShowTimer(60);
  };

  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      onClose={onClose}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex  text-white flex-col text-center gap-1">
              <h3 className="modal-heading"> Enter OTP</h3>
              <p className="text-[16px]">Please enter OTP sent to your email</p>
            </ModalHeader>
            <ModalBody>
              <div className="mail-input otp-inputs flex gap-6">
                <OTPInput
                  className="forminput"
                  value={OTP}
                  onChange={otpinputChange}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />
              </div>
              <div>
                {!showResend && (
                  <p className="register-text mt-5  text-lg ">
                    Resent OTP in {showTimer}s
                  </p>
                )}

                {showResend && (
                  <p className="resend-otp" onClick={resendOTP}>
                    Resend OTP
                  </p>
                )}
              </div>
            </ModalBody>
            <ModalFooter className="flex-col justify-self-center items-center">
              {/* <Button
                className="modal-submit-btn"
                color="primary"
                onPress={onClose}
              >
                Submit
              </Button> */}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
