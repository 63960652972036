import { ApiRequest } from "../helpers/common";

export const getContentList = (params = {}) => {
  return ApiRequest("/admin/contents", "GET", params);
};

export const getContentSegments = (params = {}) => {
  return ApiRequest("/admin/contents/segments", "GET", params);
};

export const getContentLogs = (params = {}) => {
  return ApiRequest("/admin/contents/logs", "GET", params);
};

export const getContentDetails = (id, params = {}) => {
  return ApiRequest("/admin/contents/details?id=" + id, "GET", params);
};

export const getPromptsList = (params = {}) => {
  return ApiRequest("/admin/prompts", "GET", params);
};

export const updatePrompt = (id, payload) => {
  return ApiRequest("/admin/prompts/details?id=" + id, "POST", payload);
};
