import PromptsList from "../../components/Prompts/PromptsList";

export default function Prompts() {

    return (
        <>
            <h1 className="text-3xl">Prompts</h1>
            <PromptsList/>
        </>
    )  
}