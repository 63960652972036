import { Link, Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const AdminLayout = () => {

  return (
    <>
      <div className="drawer lg:drawer-open bg-base-100">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <div className="mob-header">
            <label
              htmlFor="my-drawer"
              className="btn btn-ghost drawer-button mainDrawer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
            <Link
              className="flex items-center gap-2 shrink-0"
              to="/admin"
              title={`homepage`}
            >
              <img
                className="dashboard-logo"
                src="/images/logo-2.png"
                alt="site-logo"
              />
            </Link>
          </div>
          <main className="flex-1 min-h-screen bg-base-100">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
              <div className="bg-base-100 p-4 md:p-12 rounded-lg text-primary-content shadow-sm mt-2">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
        <div className="drawer-side z-50">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <div className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
