import React, { useEffect, useState } from "react";
import LoginModal from "./LoginModal";
import { Profile } from "../Icons/Profile";
import { Logout } from "../Icons/Logout";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import RegisterModal from "./RegisterModal";
import ResetPassword from "./ResetPassword";
import EnterOtp from "./EnterOtp";
import DemoModal from "./DemoModal";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../redux/actions/auth.actions";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileWithoutSubscription from "./ProfileWithoutSubscription";
import SubscriptionModel from "./SubscriptionModel";
import EditModal from "./EditModal";
import Reset from "./Reset";
import ReTypePass from "./ReTypePass";

export default function Header({ onLoginClick }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openForgotPass, setOpenForgotPass] = useState(false);
  const [openOtp, setOpenOtp] = useState(false);
  const [openDemo, setOpenDemo] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openPremium, setOpenPremium] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [openReType, setOpenReType] = useState(false);

  useEffect(() => {
    if (location.hash === "#login") {
      setOpenLogin(true);
      navigate("/");
    }
    if (location.hash === "#register") {
      setOpenRegister(true);
      navigate("/");
    }
    if (location.hash === "#otp") {
      setOpenOtp(true);
    }
    if (location.hash === "#resetPass") {
      handleOpenReType();
    }
    if (location.hash === "#forgot") {
      handleOpenForgotPass();
    }
  }, [location]);

  useEffect(() => {
    if (auth.authenticate && openLogin) {
      handleCloseLogin();
    }
  }, [auth.authenticate]);

  const handleOpenReType = () => {
    setOpenReType(true);
    handleCloseOtp(false);
  };

  const handleCloseReType = () => {
    setOpenReType(false);
  };

  const handleOpenProfile = () => {
    setOpenProfile(true);
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  const handleOpenPremium = () => {
    setOpenProfile(false);
    setOpenPremium(true);
  };

  const handleClosePremium = () => {
    setOpenPremium(false);
  };

  const handleOpenEdit = () => {
    setOpenProfile(false);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenResetPass = () => {
    setOpenEdit(false);
    setOpenResetPass(true);
  };

  const handleCloseResetPass = () => {
    setOpenResetPass(false);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleOpenLogin = () => {
    setOpenRegister(false);
    setOpenLogin(true);
  };

  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleOpenRegister = () => {
    setOpenLogin(false);
    setOpenRegister(true);
  };

  const handleCloseForgotPass = () => {
    setOpenForgotPass(false);
  };

  const handleOpenForgotPass = () => {
    setOpenLogin(false);
    setOpenForgotPass(true);
  };

  const handleCloseOtp = () => {
    setOpenOtp(false);
  };

  const handleOpenOtp = () => {
    setOpenRegister(false);
    setOpenForgotPass(false);
    setOpenOtp(true);
  };

  const handleCloseDemo = () => {
    setOpenDemo(false);
  };

  const handleOpenDemo = () => {
    setOpenDemo(true);
  };

  const handleLogout = () => {
    dispatch(signout());
  };

  return (
    <>
      <Navbar maxWidth="full" position="static" className="navbar-top bg-dark">
        <NavbarBrand>
          {/* <p
            onClick={(e) => navigate("/")}
            className="font-bold navbar-brand text-inherit text-white"
          >
            LOGO
          </p> */}
          <img
            onClick={(e) => navigate("/")}
            className="site-logo"
            src="/images/logo-2.png"
            alt="site-logo"
          />
        </NavbarBrand>

        <NavbarContent justify="end">
          {!auth.authenticate && (
            <NavbarItem>
              <p className="font-bold text-inherit text-white">FAQ's</p>
            </NavbarItem>
          )}

          <NavbarItem>
            {auth.authenticate ? (
              <button
                className=""
                onClick={(e) => {
                  navigate("/history");
                }}
              >
                <p className="text-[#fff] font-bold ">My Videos</p>
              </button>
            ) : (
              <button className="bookmarkBtn" onClick={handleOpenDemo}>
                <span className="IconContainer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                  >
                    <circle cx="23" cy="23" r="23" fill="white" />
                    <path
                      d="M27.4444 21.75V18.8333C27.4444 18.6123 27.3508 18.4004 27.1841 18.2441C27.0174 18.0878 26.7913 18 26.5556 18H15.8889C15.6531 18 15.427 18.0878 15.2603 18.2441C15.0937 18.4004 15 18.6123 15 18.8333V27.1667C15 27.3877 15.0937 27.5996 15.2603 27.7559C15.427 27.9122 15.6531 28 15.8889 28H26.5556C26.7913 28 27.0174 27.9122 27.1841 27.7559C27.3508 27.5996 27.4444 27.3877 27.4444 27.1667V24.25L31 27.5833V18.4167L27.4444 21.75Z"
                      fill="#0D0D0D"
                    />
                  </svg>
                </span>
                <span className="text">Demo Videos</span>
              </button>
            )}
          </NavbarItem>

          <NavbarItem>
            {auth.authenticate ? (
              <button
                className="bookmarkBtn bg-[#fff]"
                onClick={(e) => navigate("/title")}
              >
                <span className="IconContainer">
                  <svg
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="23" cy="23" r="23" fill="black" />
                    <path
                      d="M31.3077 22.3077H23.6925V14.6923C23.6925 14.5087 23.6196 14.3326 23.4898 14.2028C23.3599 14.0729 23.1838 14 23.0002 14C22.8166 14 22.6405 14.0729 22.5107 14.2028C22.3809 14.3326 22.3079 14.5087 22.3079 14.6923V22.3077H14.6927C14.5091 22.3077 14.3331 22.3806 14.2032 22.5105C14.0734 22.6403 14.0005 22.8164 14.0005 23C13.997 23.09 14.0129 23.1796 14.0471 23.2629C14.0813 23.3463 14.1329 23.4213 14.1985 23.4829C14.2641 23.5446 14.3422 23.5915 14.4274 23.6204C14.5127 23.6494 14.6032 23.6597 14.6927 23.6508H22.3079V31.3077C22.3079 31.4913 22.3809 31.6674 22.5107 31.7972C22.6405 31.9271 22.8166 32 23.0002 32C23.1838 32 23.3599 31.9271 23.4898 31.7972C23.6196 31.6674 23.6925 31.4913 23.6925 31.3077V23.6923H31.3077C31.4913 23.6923 31.6674 23.6194 31.7972 23.4895C31.9271 23.3597 32 23.1836 32 23C32 22.8164 31.9271 22.6403 31.7972 22.5105C31.6674 22.3806 31.4913 22.3077 31.3077 22.3077Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <p className="text-[#000] font-bold">New Videos</p>
              </button>
            ) : (
              <Button
                className="bg-white login-btn"
                onPress={handleOpenLogin}
                radius="full"
                as={Link}
                variant="flat"
              >
                Login
              </Button>
            )}
          </NavbarItem>

          {auth.authenticate && (
            <NavbarItem style={{ height: "inherit" }}>
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    radius="full"
                    className="p-0 h-full min-w-[64px] bg-[grey] "
                  >
                    <svg
                      width="18"
                      height="23"
                      viewBox="0 0 18 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="9"
                        cy="6.5"
                        r="5.5"
                        stroke="white"
                        stroke-width="1.4"
                      />
                      <path
                        d="M1.66666 23.0001V21.6667C1.66666 18.353 4.35295 15.6667 7.66666 15.6667L10.3333 15.6667C13.647 15.6667 16.3333 18.353 16.3333 21.6667V23.0001"
                        stroke="white"
                        stroke-width="1.4"
                      />
                    </svg>
                  </Button>
                </DropdownTrigger>
                <DropdownMenu aria-label="Static Actions">
                  <DropdownItem
                    key="new"
                    startContent={<Profile />}
                    onClick={handleOpenProfile}
                  >
                    Profile
                  </DropdownItem>
                  <DropdownItem
                    key="copy"
                    onClick={handleLogout}
                    startContent={<Logout />}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavbarItem>
          )}
        </NavbarContent>
      </Navbar>

      <LoginModal
        isOpen={openLogin}
        onClose={handleCloseLogin}
        openRegister={handleOpenRegister}
        openForgotPass={handleOpenForgotPass}
      />
      <RegisterModal
        isOpen={openRegister}
        onClose={handleCloseRegister}
        openLogin={handleOpenLogin}
        openOtp={handleOpenOtp}
      />
      <ResetPassword
        isOpen={openForgotPass}
        onClose={handleCloseForgotPass}
        openOtp={handleOpenOtp}
      />
      <EnterOtp isOpen={openOtp} onClose={handleCloseOtp} />
      <DemoModal isOpen={openDemo} onClose={handleCloseDemo} />
      <ProfileWithoutSubscription
        isOpen={openProfile}
        onClose={handleCloseProfile}
        openPremium={handleOpenPremium}
        openEdit={handleOpenEdit}
      />
      <SubscriptionModel isOpen={openPremium} onClose={handleClosePremium} />
      <EditModal
        isOpen={openEdit}
        onClose={handleCloseEdit}
        resetPass={handleOpenResetPass}
      />
      <Reset isOpen={openResetPass} onClose={handleCloseResetPass} />
      <ReTypePass isOpen={openReType} onClose={handleCloseReType} />
    </>
  );
}
