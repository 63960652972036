import React from "react";
import UpdatePromptForm from "./UpdatePromptForm";
import BaseModal from "../Modals/BaseModal";

const UpdatePromptModal = ({ isOpen, setIsOpen, model }) => {
  return (
    <BaseModal isModalOpen={isOpen} setIsModalOpen={setIsOpen} title="Edit Prompt">
      <UpdatePromptForm model={model} onOpenChange={setIsOpen} />
    </BaseModal>
  );
};

export default UpdatePromptModal;