import React from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import { LockIcon } from "../Icons/LockIcon.jsx";

import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Link,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
  Input,
  useDisclosure,
  Divider,
} from "@nextui-org/react";

export default function VoiceSettingModal({
  isOpen,
  onClose,
  voiceSettings,
  changeVoice,
}) {
  const { onOpen, onOpenChange } = useDisclosure();
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      size="3xl"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      onClose={onClose}
    >
      <ModalContent>
        <>
          <ModalHeader className="modal-heading">Voice Settings</ModalHeader>
          <ModalBody className="text-white">
            <div className="grid md:grid-cols-2 voicesetting-box">
              <button
                className={`voicesetting ${
                  voiceSettings.voice_model == "Lucia" && "bg-white text-[#000]"
                }`}
                onClick={(e) => changeVoice({ voice_model: "Lucia" })}
              >
                <span className="startedcontainer-2 bg-black">
                  <img src="/images/voicesetting.svg"></img>
                </span>

                <p className="text-black text-center text-[22px] font-normal leading-[1.4]">
                  Lucia
                </p>
              </button>
              <button
                className={`voicesetting ${
                  voiceSettings.voice_model == "Lupe" && "bg-white text-[#000]"
                }`}
                onClick={(e) => changeVoice({ voice_model: "Lupe" })}
              >
                <span className="startedcontainer-2 bg-black">
                  <img src="/images/voicesetting.svg"></img>
                </span>

                <p className="text-black text-center text-[22px] font-normal leading-[1.4]">
                  Lupe
                </p>
              </button>
              <button
                className={`voicesetting ${
                  voiceSettings.voice_model == "Mia" && "bg-white text-[#000]"
                }`}
                onClick={(e) => changeVoice({ voice_model: "Mia" })}
              >
                <span className="startedcontainer-2 bg-black">
                  <img src="/images/voicesetting.svg"></img>
                </span>

                <p className="text-black text-center text-[22px] font-normal leading-[1.4]">
                  Mia
                </p>
              </button>
            </div>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center"></ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
