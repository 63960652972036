
const FormFieldWrapper = ({ children }) => {
  return (
    <>
      <div className="form-group form-group-themed">
        {children}
      </div>
    </>
  );
};

export default FormFieldWrapper;
