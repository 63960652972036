"use client";

import { useState, useEffect } from "react";
import { initialPaginationData, printDateTime } from "../../helpers/common";
import { getContentLogs } from "../../api/admin.api";
import Pagination from "../Common/Pagination";
import { Link } from "react-router-dom";
import LogDetailsModal from "./LogDetailsModal";

function LogList({ contentId }) {

    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState(initialPaginationData());
    const [isLoading, setIsLoading] = useState(true);

    const [logModal, setLogModal] = useState({});
    const [isOpenLogModal, setIsOpenLogModal] = useState(false);

    const handleLogModalClose = (nVal) => {
        setIsOpenLogModal(nVal);
    }

    const loadData = (showLoading = true) => {
        if (showLoading) {
            setIsLoading(true);
        }

        const query = {
            referenceId: contentId,
            page,
            limit: 25
        };

        getContentLogs(query)
            .then((res) => {
                const content = res?.data;
                setPaginationData(content);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });

    };

    const handlePageChange = (newPageNo) => {
        setPage(newPageNo);
    };

    useEffect(() => {
        loadData();
    }, [page]);

    const handleOpenLog = (item) => {
        setLogModal(item);
        setIsOpenLogModal(true);
    }

    return (
        <>
            <div className="card bg-base-200 w-100 conent-card-body-parent my-5">
                <div className="card-body conent-card-body">
                    <div className="overflow-x-auto w-full">
                        <table className="table table-zebra w-full">
                            <thead>
                                <tr>
                                    <th>Particular</th>
                                    <th>Created At</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isLoading ? <>
                                        <tr>
                                            <td><span className="skeleton w-full h-6 block"></span></td>
                                            <td><span className="skeleton w-full h-6 block"></span></td>
                                            <td><span className="skeleton w-full h-6 block"></span></td>
                                        </tr>
                                    </> : <>
                                        {
                                            paginationData?.docs && paginationData?.docs.length > 0 ? <>
                                                {paginationData?.docs.map((item) => (
                                                    <tr key={item?._id}>
                                                        <td>{(item?.message)}</td>
                                                        <td className="text-left">{printDateTime(item?.created_at)}</td>
                                                        <td className="text-center p-0">
                                                            <button onClick={()=>handleOpenLog(item)} className="btn btn-secondary normal-case btn-sm">
                                                                View Data
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </> : <>
                                                <tr>
                                                    <td colSpan={3} className="text-center p-5 lighttxt">No Logs Found</td>
                                                </tr>
                                            </>
                                        }
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                    {paginationData.totalDocs > 0 ? (
                        <Pagination
                            onPageChange={handlePageChange}
                            paginationData={paginationData}
                        />
                    ) : null}
                </div>
            </div>

            <LogDetailsModal
                model={logModal}
                isOpen={isOpenLogModal}
                setIsOpen={handleLogModalClose}
            />

        </>
    );
}

export default LogList;