import React from "react";
import BaseModal from "../Modals/BaseModal";

const SegmentDetailsModal = ({ isOpen, setIsOpen, model }) => {

  const videos = model?.videos ? JSON.parse(model?.videos) : [];

  return (
    <BaseModal isModalOpen={isOpen} setIsModalOpen={setIsOpen} title={`Segment Details #${model?.orderIndex}`}>
      <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
          <thead>
            <tr>
              <th>Link</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {videos.map((video) => (
              <tr key={video?._id}>
                <td>{(video?.link)}</td>
                <td>{(video?.duration)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </BaseModal>
  );
};

export default SegmentDetailsModal;