import { useEffect, useState } from "react";
import FormFieldWrapper from "./FormFieldWrapper";

const FormFieldTextarea = (props) => {
  const {
    label = null,
    value = "",
    errorText,
    fieldType,
    showError = false,
  } = props;

  const [defaultType, setDefaultType] = useState("text");
  const [haveError, setHaveError] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
    setDefaultType(fieldType ?? "text");
  }, [props]);

  const inputOptions = {
    label,
    value,
    radius: "none",
    ...props,
    type: defaultType,
  };

  if (defaultType === "password") {
    inputOptions.type = isVisible ? "text" : "password";
  }

  if (haveError) {
    inputOptions["errorMessage"] = errorText;
  }

  return (
    <>
      <FormFieldWrapper>
        {label != null ? (
          <>
            <label className="form-label" htmlFor={props.id}>
              {label}
            </label>
          </>
        ) : null}
        {
          <textarea
            className="textarea textarea-bordered w-full placeholder:opacity-60"
            rows={6}
            {...inputOptions}
          />
        }
        {(haveError != null && showError) ? (
          <>
            <p className="form-error" htmlFor={props.id}>
              {errorText}
            </p>
          </>
        ) : null}
      </FormFieldWrapper>
    </>
  );
};

export default FormFieldTextarea;
