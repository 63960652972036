import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getVideoData } from "../../api/user.api.js";
import { haveValue } from "../../helpers/common.js";
import VideoGenerated from "../../components/Video/VideoGenerated.js";
import ScriptGenerated from "../../components/Video/ScriptGenerated.js";

export default function VideoEditor() {

  const { videoId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [videoData, setVideoData] = useState({});


  const loadData = () => {
    setLoading(true);
    if (haveValue(videoId)) {
      getVideoData(videoId)
        .then((res) => {
          if (haveValue(res?.data?._id)) {
            setLoading(false);
            setVideoData(res.data);
          } else {
            navigate('/history');
          }
        })
        .catch((err) => {
          navigate('/history');
        });
    }
  }

  useEffect(() => {
    loadData();
  }, [videoId]);

  const handleReload = () => {
    loadData();
  }

  return (
    <>
      {
        loading ? <>
          <div className="flex w-full flex-col gap-4">
            <div className="skeleton h-32 w-full"></div>
            <div className="skeleton h-4 w-full"></div>
          </div>
        </> : <>
          {
            haveValue(videoData?.videoFileUrl) ? <>
              <VideoGenerated onReload={handleReload} videoData={videoData} />
            </> : <>
              <ScriptGenerated onReload={handleReload} videoData={videoData} />
            </>
          }
        </>
      }
    </>
  );
}
