import * as Joi from "joi-browser";
import axiosInstance from "./axios";
import moment from "moment";

export const haveValue = (val) => {
  if (
    val !== "" &&
    val !== "undefined" &&
    val !== undefined &&
    val !== null &&
    val !== "null"
  ) {
    return true;
  } else {
    return false;
  }
};

export const validateJOIFormField = (formFields, schema) => {
  const result = Joi.validate(formFields, schema, {
    abortEarly: false,
    stripUnknown: true,
  });
  const { error } = result;
  if (!error) {
    return null;
  } else {
    const errorData = {};
    for (let item of error.details) {
      const name = item.path[0];
      errorData[name] = item.message;
    }
    return errorData;
  }
};

export const validateJOIProperty = (schema, event) => {
  const { name, value } = event.target;
  const obj = { [name]: value };
  if (schema.hasOwnProperty(name)) {
    const subSchema = { [name]: schema[name] };
    const result = Joi.validate(obj, subSchema, {
      stripUnknown: true,
    });
    const { error } = result;
    return error ? error.details[0].message : null;
  } else {
    return null;
  }
};

export const getToken = () => {
  return localStorage.getItem("token") || null;
};

export const checkToken = () => {
  let token = getToken();
  if (token) {
    return true;
  }
  return false;
};

export const ApiRequest = async (url, method = "POST", payload = {}) => {
  try {
    const options = {
      url,
      method,
    };

    if (method === "POST") {
      options.data = payload;
    } else if (method === "GET") {
      options.params = payload;
    }

    const res = await axiosInstance(options);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const initialPaginationData = () => {
  return {
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    offset: 0,
    page: 0,
    pagingCounter: 0,
    totalDocs: 0,
    totalPages: 0,
  };
};

export const objectToFormData = (obj, form = null, namespace = null) => {
  const fd = form || new FormData();
  let formKey = null;
  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof Blob) &&
        !(obj[property] instanceof File) &&
        !(obj[property] instanceof Array)
      ) {
        objectToFormData(obj[property], fd, property);
      } else if (obj[property] instanceof Array) {
        for (let i = 0; i < obj[property].length; i++) {
          fd.append(formKey + "[]", obj[property][i]);
        }
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const isInputEvent = (event) => {
  if (typeof event === "object") {
    if (Object.prototype.hasOwnProperty.call(event, "target")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const handleFormikChange = (event, formik, fieldName = null) => {
  if (isInputEvent(event)) {
    const target = event.target;
    fieldName = target?.name;
    formik?.handleChange(event);
  } else {
    formik?.setFieldValue(fieldName, event);
  }
  setTimeout(() => {
    formik?.setFieldTouched(fieldName, true);
  }, 300);
};

export const handleFormikSubmit = (e, formik) => {
  e?.preventDefault();
  e?.stopPropagation();
  const fieldsToMarkTouched = {};
  const fields = Object.keys(formik?.initialValues);
  for (const field of fields) {
    fieldsToMarkTouched[field] = true;
  }
  formik?.handleSubmit();
  setTimeout(() => {
    formik?.setTouched(fieldsToMarkTouched, true);
  }, 300);
};

export const capitalize = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

export const camelToTitleCase = (str) => {
  if (str) {
    const spacedString = str.replace(/([a-z])([A-Z])/g, "$1 $2");
    const titleCaseString = spacedString
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());

    return titleCaseString;
  }
  return str;
};

export const printDateTime = (timeData) => {
  return moment(timeData).format("ll LT");
};

export const statusBadgeClass = (statusTxt) => {
  switch (statusTxt) {
    case "pending":
      return "badge badge-neutral";
      return;
    case "scriptGenerated":
      return "badge badge-secondary";
      return;
    case "creatingVideo":
      return "badge badge-warning";
      return;
    case "completed":
      return "badge badge-success";
      return;
    case "failed":
      return "badge badge-error";
      return;
  }
  return "badge badge-info";
}

export const nl2br = (str) => {
  if (typeof str === "string") {
    return str.replace(/\n/g, '<br>');
  }
  return str;
}
