import React, { useEffect, useState } from "react";

import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import { getVideoData } from "../../api/user.api.js";
import ScriptDesc from "../ScriptDesc.js";
import { camelToTitleCase, statusBadgeClass } from "../../helpers/common.js";

export default function VideoGenerated({videoData}) {
  
  const [viewDesc, setViewDesc] = useState(false);

  const handleViewDesc = () => {
    setViewDesc(true);
  };
  const handleCloseViewDesc = () => {
    setViewDesc(false);
  };

  return (
    <>
      <section className="script_section overflow-hidden">
        <div className="container login-banner pt-5  mx-auto md:flex   gap-26 border-top-grey">
          <div className="flex login-banner generated-video-container flex-col md:w-[50%]   md:gap-26 ">
            <div className="md:flex gap-3 md:gap-7">
              <div>
                <p className="mb-3"><span className={statusBadgeClass(videoData?.status)}>{camelToTitleCase(videoData?.status)}</span></p>
                <div className="rounded-3xl rounded-tl-none p-4 bg-[grey]">
                  <p className="text-white">{videoData?.title}</p>
                </div>
              </div>
            </div>
            <p
              className="text-[#fff] flex gap-3 items-center"
              onClick={handleViewDesc}
            >
              <span>View Description </span>
              <span>
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7.54748 7.00483C7.69084 7.13486 7.80465 7.2897 7.88233 7.46036C7.96 7.63103 8 7.81414 8 7.99909C8 8.18403 7.96 8.36714 7.88233 8.53781C7.80465 8.70848 7.69084 8.86331 7.54748 8.99334L1 15"
                    stroke="white"
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
            </p>
            <div className="md:flex gap-3 md:gap-7">
              {/* <div className="bg-white p-4 w-[58px] h-[58px] flex justify-center items-center rounded-full md:mb-0 mb-2">
                <Profile></Profile>
              </div> */}
              <div className="rounded-3xl rounded-tl-none px-4 py-5 bg-[grey]">
                <div className="font-bold flex justify-between items-center mb-6 text-white text-[#fff]">
                  <p className="text-[24px]">Script</p>
                </div>
                <div className="script_div">
                  <p>{videoData?.script || ""}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="md:w-[50%] border-l border-l-2 border-l-[#3D3D3D] items-center justify-center flex flex-col gap-11 h-full ">
            <div className="script-video-src">
              {/* <video width="600" controls>
                <source src={videoData?.videoFileUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
              <iframe src={videoData?.videoFileUrl}></iframe>
            </div>
            <div className="">
              <div className="md:flex justify-center items-center gap-4">
                <div className=" border text-[#fff] px-6 py-3 border-[#fff] text-[18px] p-2 flex items-center justify-center gap-4   rounded-full ">
                  <img src="/images/share.svg"></img> Share to Youtube
                </div>
                <div className="my-2 my:mt-0border border-[#3D3D3D]  rounded-full  ">
                  {/* <img src="/images/download.svg"></img> */}
                  <Dropdown>
                    <DropdownTrigger>
                      <Button className="border text-[#fff] h-[53px] w-[222px] border-[#fff ] text-[18px]  flex items-center justify-center bg-transparent gap-4  rounded-full  ">
                        <img src="/images/download.svg"></img> Download
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      className="download-video-dropdown"
                      aria-label="Static Actions"
                    >
                      <DropdownItem className="first-item" key="">
                        Download Video
                      </DropdownItem>
                      <DropdownItem key="copy">
                        Download in
                        <span className="highlighted-text"> 4k</span>
                      </DropdownItem>
                      <DropdownItem key="edit">
                        Download in
                        <span className="highlighted-text"> 1080p</span>
                      </DropdownItem>
                      <DropdownItem key="edit">
                        Download in
                        <span className="highlighted-text"> 720p</span>
                      </DropdownItem>
                      <DropdownItem key="edit">
                        Download in
                        <span className="highlighted-text"> 480p</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScriptDesc
        isOpen={viewDesc}
        onClose={handleCloseViewDesc}
        scriptData={videoData}
      />
    </>
  );
}
