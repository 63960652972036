import { ApiRequest } from "../helpers/common";

export const resendOtp = (payload) => {
  return ApiRequest("/auth/resend-otp", "POST", payload);
};

export const verifyOTP = (payload) => {
  return ApiRequest("/auth/otp-verification", "POST", payload);
};

export const generateScript = (payload) => {
  return ApiRequest("/content/create-script", "POST", payload);
};

export const getVideoData = (video_id) => {
  return ApiRequest(`/content/get-video/${video_id}`, "GET", {});
};

export const compileVideo = (payload) => {
  return ApiRequest("/content/compile-video", "POST", payload);
};

export const resetPass = (payload) => {
  return ApiRequest("/auth/reset/password", "POST", payload);
};

export const changePass = (payload) => {
  return ApiRequest("/user/changePassword", "POST", payload);
};

export const contentHistory = (userId) => {
  return ApiRequest(`/user/video/history/${userId}`, "GET", {});
};
