import "./App.css";
import "./output.css";
import "./main.css";
import "./styles/dashboard.css";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import AppRouter from "./AppRouter";

function App() {

  return (
    <>
      <Toaster
        toastOptions={{
          duration: 3000,
        }}
      />
      <ToastContainer />
      <AppRouter />
    </>
  );
}

export default App;
