import React, { useState } from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";

import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  useDisclosure,
} from "@nextui-org/react";
import {
  validateJOIFormField,
  validateJOIProperty,
} from "../helpers/common.js";
import Joi from "joi-browser";
import { resendOtp } from "../api/user.api.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function ResetPassword({ isOpen, onClose, openOtp }) {
  const { onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = React.useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});

  const schema = {
    email: Joi.string().email().required(),
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const errorData = { ...errors };
    var errorMessage = validateJOIProperty(schema, e);
    if (errorMessage) {
      errorData[name] = errorMessage;
    } else {
      errorData[name] && delete errorData[name];
    }
    setErrors(errorData);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSentOTP = () => {
    try {
      let error = validateJOIFormField(formData, schema);
      if (error) {
        setErrors(error);
        console.log(error);
        return;
      }
      resendOtp({ email: formData?.email })
        .then((res) => {
          localStorage.setItem(
            "user",
            JSON.stringify({ email: formData?.email })
          );
          navigate("#forgotPassword");
          openOtp();
        })
        .catch((err) => {
          const errorMessage =
            err.response && err.response.data
              ? err.response.data.message
              : err.message;
          toast.error(errorMessage);
        });
    } catch (err) {
      console.log("COMING HERE", err);
    }
  };

  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onClose={onClose}
      placement="top-center"
    >
      <ModalContent>
        <>
          <ModalHeader className="flex  text-white flex-col text-center gap-1">
            <h3 className="modal-heading"> Forgot Password</h3>
            <p className="text-[16px]">
              Please enter your email to reset your password
            </p>
          </ModalHeader>
          <ModalBody className="text-white">
            <div className="mail-input form-field flex gap-6">
              <Input
                isRequired
                className="text-white"
                classNames={{
                  label: "text-white !important",
                }}
                style={{
                  label: { color: "white !important" },
                }}
                radius="full"
                type="email"
                label="Email"
                placeholder="Enter Email"
                labelPlacement="outside"
                variant="bordered"
                endContent={
                  <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                }
                name="email"
                onChange={handleInputChange}
              />
              {errors.email && (
                <p className="validation_error">{errors.email}</p>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center">
            <Button
              className="modal-submit-btn"
              color="primary"
              onPress={handleSentOTP}
            >
              Next
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
