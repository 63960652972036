import React, { useState } from "react";
import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Link,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  useDisclosure,
} from "@nextui-org/react";
import Joi from "joi-browser";
import {
  validateJOIFormField,
  validateJOIProperty,
} from "../helpers/common.js";
import { resetPass } from "../api/user.api.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function ReTypePass({ isOpen, onClose }) {
  const { onOpen, onOpenChange } = useDisclosure();
  const schema = {
    password: Joi.string().required(),
    confirm_password: Joi.string().required(),
  };
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = React.useState(false);
  const [isVisibleRe, setIsVisibleRe] = React.useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});

  const toggleVisibility = () => setIsVisible(!isVisible);
  const toggleVisibilityRe = () => setIsVisibleRe(!isVisibleRe);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const errorData = { ...errors };
    var errorMessage = validateJOIProperty(schema, e);
    if (errorMessage) {
      errorData[name] = errorMessage;
    } else {
      errorData[name] && delete errorData[name];
    }
    setErrors(errorData);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangePassword = () => {
    try {
      let error = validateJOIFormField(formData, schema);
      if (error) {
        setErrors(error);
        return;
      }
      if (formData?.password != formData?.confirm_password) {
        setErrors((prev) => ({
          ...prev,
          confirm_password: "Password does not match",
        }));
        return false;
      }
      const user = JSON.parse(localStorage.getItem("user"));
      const temp = {
        email: user.email,
        password: formData?.password,
        confirm_password: formData?.confirm_password,
      };
      resetPass(temp)
        .then((res) => {
          navigate("/");
          window.location.reload();
        })
        .catch((err) => console.log(err));
    } catch (err) {
      const errorMessage =
        err.response && err.response.data
          ? err.response.data.message
          : err.message;
      toast.error(errorMessage);
    }
  };

  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      onClose={onClose}
    >
      <ModalContent>
        <>
          <ModalHeader className="flex  text-white flex-col text-center gap-1">
            <h3 className="modal-heading"> Reset Password</h3>
            <p className="text-[16px]">
              Please enter your email to reset your password
            </p>
          </ModalHeader>
          <ModalBody className="text-white">
            <div className="password-input form-field">
              <Input
                endContent={
                  <button
                    className="focus:outline-none text-white border-solid border-white"
                    type="button"
                    classNames={{
                      label: "text-white",
                    }}
                    onClick={toggleVisibility}
                    aria-label="toggle password visibility"
                  >
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                radius="full"
                label="New Password"
                placeholder="Enter your password"
                labelPlacement="outside"
                variant="bordered"
                name="password"
                onChange={handleInputChange}
              />
              {errors.password && (
                <p className="validation_error">{errors.password}</p>
              )}
            </div>
            <div className="password-input form-field">
              <Input
                endContent={
                  <button
                    className="focus:outline-none text-white border-solid border-white"
                    type="button"
                    classNames={{
                      label: "text-white",
                    }}
                    onClick={toggleVisibilityRe}
                    aria-label="toggle password visibility"
                  >
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisibleRe ? "text" : "password"}
                radius="full"
                label="Re-Type New Password"
                placeholder="Enter your password"
                labelPlacement="outside"
                variant="bordered"
                name="confirm_password"
                onChange={handleInputChange}
              />
              {errors.confirm_password && (
                <p className="validation_error">{errors.confirm_password}</p>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center">
            <Button
              className="modal-submit-btn"
              color="primary"
              onPress={handleChangePassword}
            >
              Reset
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
