import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";

export default function Sidebar() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <div className="sidebar-brand">
                <Link
                    className="flex items-center gap-2 shrink-0"
                    to="/"
                    title={`homepage`}
                >
                    <img
                        className="dashboard-logo"
                        src="/images/logo-2.png"
                        alt="site-logo"
                    />
                </Link>
            </div>
            <ul className="pt-5">
                <li>
                    <details open>
                        <summary>
                            <svg
                                width="18"
                                height="18"
                                className="text-white w-5 h-5"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="6"
                                    y="6"
                                    width="36"
                                    height="36"
                                    rx="3"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinejoin="bevel"
                                ></rect>
                                <path
                                    d="M6 22L42 22"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="butt"
                                    strokeLinejoin="bevel"
                                ></path>
                                <path
                                    d="M29 22V6"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="butt"
                                    strokeLinejoin="bevel"
                                ></path>
                                <path
                                    d="M26 6H32"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="butt"
                                    strokeLinejoin="bevel"
                                ></path>
                                <path
                                    d="M6 19V25"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="butt"
                                    strokeLinejoin="bevel"
                                ></path>
                                <path
                                    d="M42 19V25"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="butt"
                                    strokeLinejoin="bevel"
                                ></path>
                            </svg>
                            <span>Content</span>
                        </summary>
                        <ul>
                            <li>
                                <Link to="/title">
                                    Create Video
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/contents">
                                    All Content
                                </Link>
                            </li>
                            <li>
                                <Link to="/settings/prompts">
                                    Prompt Settings
                                </Link>
                            </li>
                        </ul>
                    </details>
                </li>
                <li>
                    <ProfileDropdown layout="admin" />
                </li>
            </ul>
        </>
    );
}