import { useEffect, useState } from "react";
import FormFieldWrapper from "./FormFieldWrapper";

const FormField = (props) => {
  const {
    label = null,
    value = "",
    errorText,
    fieldType,
    showError = false,
    hint = null, // New prop for hint text
  } = props;

  const [defaultType, setDefaultType] = useState("text");
  const [haveError, setHaveError] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
    setDefaultType(fieldType ?? "text");
  }, [props]);

  const inputOptions = {
    label,
    value,
    radius: "none",
    ...props,
    type: defaultType
  };

  if (defaultType === "password") {
    inputOptions.type = isVisible ? "text" : "password";
  }

  if (haveError) {
    inputOptions["errorMessage"] = errorText;
  }

  return (
    <>
      <FormFieldWrapper>
        {label != null ? (
          <label className="form-label" htmlFor={props.id}>
            {label}
          </label>
        ) : null}
        <input
          className="input input-bordered w-full placeholder:opacity-60"
          {...inputOptions}
        />
        <div className="flex justify-between items-start mt-1">
          {(haveError != null && showError) ? (
            <p className="form-error w-full" htmlFor={props.id}>
              {errorText}
            </p>
          ) : null}
          {hint && (
            <p className="text-sm text-gray-500 w-full text-right">{hint}</p>
          )}
        </div>
      </FormFieldWrapper>
    </>
  );
};

export default FormField;
