import { toast } from "react-toastify";
import axiosInstance from "../../helpers/axios";
import { auth_constants } from "./constants";

export const login = (user) => {
  return async (dispatch) => {
    dispatch({ type: auth_constants.PROFILE_REQUEST });

    try {
      const res = await axiosInstance.post("/auth/login", {
        ...user,
      });

      if (res.status == 200) {
        const data = res.data;
        const { token, user } = data.data;
        const userTemp = {
          _id: user._id,
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
          email: user.email,
          activated: user.activated,
        };
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(userTemp));
        dispatch({
          type: auth_constants.PROFILE_SUCCESS,
          payload: { token, userTemp },
        });
        toast.success("Logged In");
      }
    } catch (err) {
      let errorMessage =
        err.response && err.response.data
          ? err.response.data.message
          : err.message;

      dispatch({
        type: auth_constants.PROFILE_FAILURE,
        payload: { error: errorMessage },
      });
      toast.error("Invalid Email Or Password");
    }
  };
};

export const register = (user) => {
  return async (dispatch) => {
    dispatch({ type: auth_constants.PROFILE_REQUEST });

    try {
      const res = await axiosInstance.post("/auth/signup", {
        ...user,
      });

      if (res.status == 200) {
        const data = res.data;
        const { token, user } = data.data;
        const userTemp = {
          _id: user._id,
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
          email: user.email,
          activated: user.activated,
        };
        dispatch({
          type: auth_constants.PROFILE_SUCCESS,
          payload: { token, userTemp },
        });
        localStorage.setItem("user", JSON.stringify(userTemp));
        localStorage.setItem("token", JSON.stringify(token));
      }
      return { success: true };
    } catch (err) {
      let errorMessage =
        err.response && err.response.data
          ? err.response.data.message
          : err.message;

      dispatch({
        type: auth_constants.PROFILE_FAILURE,
        payload: { error: errorMessage },
      });
      // console.log(err.response.data.message);
      toast.error(errorMessage);
      return { success: false };
    }
  };
};

export const googleAuth = (googleCode) => {
  return async (dispatch) => {
    dispatch({ type: auth_constants.PROFILE_REQUEST });
    try {
      const res = await axiosInstance.post("/auth/googleAuth", {
        googleCode,
      });

      if (res.status == 200) {
        const data = res.data;
        const { token, user } = data.data;
        const userTemp = {
          _id: user._id,
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
          email: user.email,
          activated: user.activated,
        };
        dispatch({
          type: auth_constants.PROFILE_SUCCESS,
          payload: { token, userTemp },
        });
        localStorage.setItem("user", JSON.stringify(userTemp));
        localStorage.setItem("token", token);
      }
      return { success: true };
    } catch (error) {
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      console.log(errorMessage, "ERROR THIS IS SHOWING", error);
      dispatch({
        type: auth_constants.PROFILE_FAILURE,
        payload: { error: errorMessage },
      });
      toast.error(errorMessage);
      return { success: false };
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch({
        type: auth_constants.PROFILE_SUCCESS,
        payload: {
          token,
          user,
        },
      });
    } else {
      dispatch({
        type: auth_constants.PROFILE_FAILURE,
        payload: { error: null },
      });
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    localStorage.clear();
    dispatch({ type: auth_constants.LOGOUT_SUCCESS });
  };
};

export const updateUser = (user, id) => {
  return async (dispatch) => {
    dispatch({ type: auth_constants.PROFILE_REQUEST });
    try {
      const res = await axiosInstance.post("/user/update", {
        id,
        data: user,
      });

      if (res.status == 200) {
        const data = res.data;
        const { user } = data.data;
        const userTemp = {
          _id: user._id,
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
          email: user.email,
          activated: user.activated,
        };
        const token = localStorage.getItem("token");
        dispatch({
          type: auth_constants.PROFILE_SUCCESS,
          payload: { token, userTemp },
        });
        localStorage.setItem("user", JSON.stringify(userTemp));
      }
      return { success: true };
    } catch (error) {
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;

      dispatch({
        type: auth_constants.PROFILE_FAILURE,
        payload: { error: errorMessage },
      });
      toast.error(errorMessage);
      return { success: false };
    }
  };
};
