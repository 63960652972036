import React, { useEffect, useState } from "react";
import { EditIcon } from "../Icons/EditIcon.jsx";

import { Profile } from "../Icons/Profile.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { haveValue } from "../helpers/common.js";
import { Textarea } from "@nextui-org/react";
import VoiceSettingModal from "./VoiceSettingModal.js";
import CaptionSetting from "./CaptionSetting.js";
import GenerationLoader from "./GenerationLoader.js";
import { compileVideo, generateScript } from "../api/user.api.js";
import MainLoader from "./MainLoader.js";
import ScriptDesc from "./ScriptDesc.js";

export default function ScriptGenerated() {
  const queryParameters = new URLSearchParams(window.location.search);
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingScript, setLoadingScript] = useState(false);
  const [generatedScript, setGeneratedScript] = useState(null);
  const [editScript, setEditScript] = useState(false);
  const [voiceModal, setVoiceModal] = useState(false);
  const [captionModal, setCaptionModal] = useState(false);
  const [viewDesc, setViewDesc] = useState(false);
  const [voiceSettings, setVoiceSettings] = useState({
    voice_model: "Lucia",
  });
  const [captionSettings, setCaptionSettings] = useState({
    caption_text_color: "#FFFFFF",
    caption_bg_color: "BLACK",
    caption_text_size: "20",
    caption_position: "BOTTOM",
  });
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    if (haveValue(location.state)) {
      handleGenerateScript();
    } else {
      navigate("/title");
    }
  }, []);

  const handleGenerateScript = () => {
    const temp = {
      title: location.state.title,
      description: location.state.description,
    };
    setLoadingScript(true);

    generateScript(temp)
      .then((res) => {
        console.log(res);
        setGeneratedScript(res.data.content);
        setLoadingScript(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingScript(false);
        toast.error("Error in generating script!");
      });
  };

  const handleEditScript = () => {
    setEditScript((prev) => !prev);
  };
  const handleVoiceModal = () => {
    setVoiceModal(true);
  };
  const handleCaptionModal = () => {
    setCaptionModal(true);
  };

  const handleChangeScript = (e) => {
    setGeneratedScript((prev) => ({ ...prev, script: e.target.value }));
  };

  const handleCloseVoice = () => {
    setVoiceModal(false);
  };
  const handleCloseCaption = () => {
    setCaptionModal(false);
  };
  const handleVoiceChange = (voice_type) => {
    setVoiceSettings(voice_type);
  };
  const handleCaptionChange = (caption_data) => {
    console.log(caption_data, "caption_datacaption_data");
    setCaptionSettings(caption_data);
  };

  const handleCompileVideo = () => {
    const temp = {
      content_id: generatedScript?._id,
      voice_model: voiceSettings.voice_model,
      caption_text_color: captionSettings.caption_text_color,
      caption_bg_color: captionSettings.caption_bg_color,
      caption_text_size: captionSettings.caption_text_size,
      caption_position: captionSettings.caption_position,
      script: generatedScript.script,
    };
    setContentLoading(true);
    compileVideo(temp)
      .then((res) => {
        navigate(`/video/generated/${res.data._id}`);
      })
      .catch((err) => {
        toast.error("Error while generating video");
        setContentLoading(false);
      });
  };

  const handleViewDesc = () => {
    setViewDesc(true);
  };
  const handleCloseViewDesc = () => {
    setViewDesc(false);
  };

  return (
    <>
      {contentLoading ? (
        <GenerationLoader loadingText="Generating Video..." />
      ) : (
        <section className="script_section">
          <div className="container login-banner pt-5  mx-auto flex   flex-col   gap-26 ">
            <div className="md:flex gap-3 md:gap-7">
              <div className="bg-white p-4 w-[58px] h-[58px] flex justify-center items-center rounded-full md:mb-0 mb-2">
                <Profile></Profile>
              </div>
              <div className="rounded-3xl rounded-tl-none p-4 bg-[grey]">
                <p className="text-white">{location.state.title}</p>
              </div>
            </div>
            <p
              className="text-[#fff] flex gap-3 items-center md:pl-[70px]"
              onClick={handleViewDesc}
            >
              <span>View Description </span>
              <span>
                <svg
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7.54748 7.00483C7.69084 7.13486 7.80465 7.2897 7.88233 7.46036C7.96 7.63103 8 7.81414 8 7.99909C8 8.18403 7.96 8.36714 7.88233 8.53781C7.80465 8.70848 7.69084 8.86331 7.54748 8.99334L1 15"
                    stroke="white"
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>{" "}
            </p>
            {loadingScript ? (
              <GenerationLoader loadingText="Generating Script..." />
            ) : (
              haveValue(generatedScript) && (
                <div className="md:flex gap-3 md:gap-7">
                  <div className="bg-white p-4 w-[58px] h-[58px] flex justify-center items-center rounded-full md:mb-0 mb-2">
                    <Profile></Profile>
                  </div>
                  <div className="rounded-3xl rounded-tl-none w-full px-4 py-5 bg-[grey] script-container">
                    <div className="font-bold flex justify-between items-center mb-6 text-white text-[#fff]">
                      <p className="text-[24px]">Script</p>
                      <div className="flex gap-3">
                        <div
                          className="border rounded-full p-3 w-[48px] flex items-center justify-center border-[#575757]"
                          onClick={handleEditScript}
                        >
                          <EditIcon />
                        </div>
                        <div
                          className="border rounded-full w-[48px] p-3 flex items-center justify-center border-[#575757]"
                          onClick={handleVoiceModal}
                        >
                          <img src="/images/VoiceIcon.svg" />
                        </div>
                        <div
                          className="border rounded-full w-[48px] p-3 flex items-center justify-center border-[#575757]"
                          onClick={handleCaptionModal}
                        >
                          <img src="/images/Captionicon.svg" />
                        </div>
                      </div>
                    </div>
                    {editScript ? (
                      <Textarea
                        className=" w-full edit-script-input"
                        classNames={{
                          inputWrapper: " border rounded-[25px] py-6",
                        }}
                        labelPlacement="outside"
                        onChange={handleChangeScript}
                        defaultValue={generatedScript.script}
                      />
                    ) : (
                      <p className="text-[#fff] script-text">
                        {generatedScript.script || ""}
                      </p>
                    )}
                  </div>
                </div>
              )
            )}

            <div className="md:flex gap-5 justify-center items-center">
              <div className="flex gap-4 mt-2 md:mt-0">
                <img src="/images/backicon.svg"></img>{" "}
                <p className="text-[#fff]" onClick={(e) => navigate("/title")}>
                  {" "}
                  Go back
                </p>
              </div>
              {!loadingScript && (
                <>
                  <div className="genarated-script-btns mt-2 md:mt-0">
                    <button
                      className="startedbtn regenrate-btn w-full md:w-auto"
                      onClick={handleGenerateScript}
                    >
                      <span className="startedcontainer">
                        <img src="/images/regenrate.svg"></img>
                      </span>
                      <p className="text-white text-[22px] font-normal leading-[1.4]">
                        Regenerate
                      </p>
                    </button>
                  </div>
                  <div className="genarated-script-btns mt-2 md:mt-0">
                    <button
                      className="startedbtn bg-white text-[#000] w-full md:w-auto"
                      onClick={handleCompileVideo}
                    >
                      <span className="startedcontainer-1 bg-black">
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              d="M6 12H18M18 12L13 7M18 12L13 17"
                              stroke="#FFF"
                              stroke-width="0.648"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>{" "}
                          </g>
                        </svg>
                      </span>

                      <p className="text-black text-center text-[22px] font-normal leading-[1.4]">
                        Create Video
                      </p>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      )}

      <VoiceSettingModal
        isOpen={voiceModal}
        onClose={handleCloseVoice}
        voiceSettings={voiceSettings}
        changeVoice={handleVoiceChange}
      />
      <CaptionSetting
        isOpen={captionModal}
        onClose={handleCloseCaption}
        captionSettings={captionSettings}
        changeCaption={handleCaptionChange}
      />
      <ScriptDesc
        isOpen={viewDesc}
        onClose={handleCloseViewDesc}
        scriptData={generatedScript}
      />
    </>
  );
}
