import React, { useEffect, useState } from "react";
import { Profile } from "../../Icons/Profile";
import { Logout } from "../../Icons/Logout";
import {
    Button,
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
} from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../../redux/actions/auth.actions";
import ProfileWithoutSubscription from "../ProfileWithoutSubscription";
import SubscriptionModel from "../SubscriptionModel";
import EditModal from "../EditModal";
import Reset from "../Reset";
import { useNavigate } from "react-router-dom";

export default function ProfileDropdown({ layout = 'default' }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);

    const [openProfile, setOpenProfile] = useState(false);
    const [openPremium, setOpenPremium] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openResetPass, setOpenResetPass] = useState(false);

    const handleOpenProfile = (e) => {
        e.preventDefault();
        setOpenProfile(true);
    };

    const handleCloseProfile = () => {
        setOpenProfile(false);
    };

    const handleOpenPremium = () => {
        setOpenProfile(false);
        setOpenPremium(true);
    };

    const handleClosePremium = () => {
        setOpenPremium(false);
    };

    const handleOpenEdit = () => {
        setOpenProfile(false);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleOpenResetPass = () => {
        setOpenEdit(false);
        setOpenResetPass(true);
    };

    const handleCloseResetPass = () => {
        setOpenResetPass(false);
    };

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(signout());
    };

    return (
        <>
            {
                layout === "admin" ? <>
                    <details open>
                        <summary>
                            <svg
                                width="18"
                                height="18"
                                className="text-white w-5 h-5"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4 6H44V36H29L24 41L19 36H4V6Z"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="butt"
                                    strokeLinejoin="bevel"
                                ></path>
                                <path
                                    d="M23 21H25.0025"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="butt"
                                ></path>
                                <path
                                    d="M33.001 21H34.9999"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="butt"
                                ></path>
                                <path
                                    d="M13.001 21H14.9999"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="butt"
                                ></path>
                            </svg>
                            <span>Profile</span>
                        </summary>
                        <ul>
                            <li>
                                <a href="/" onClick={handleOpenProfile}>Profile</a>
                                <a href="/" onClick={handleLogout}>Logout</a>
                            </li>
                        </ul>
                    </details>
                </> : <>

                    <Dropdown>
                        <DropdownTrigger>
                            <Button
                                radius="full"
                                className="p-0 h-full min-w-[64px] bg-[grey] "
                            >
                                <svg
                                    width="18"
                                    height="23"
                                    viewBox="0 0 18 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="9"
                                        cy="6.5"
                                        r="5.5"
                                        stroke="white"
                                        stroke-width="1.4"
                                    />
                                    <path
                                        d="M1.66666 23.0001V21.6667C1.66666 18.353 4.35295 15.6667 7.66666 15.6667L10.3333 15.6667C13.647 15.6667 16.3333 18.353 16.3333 21.6667V23.0001"
                                        stroke="white"
                                        stroke-width="1.4"
                                    />
                                </svg>
                            </Button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Static Actions">
                            <DropdownItem
                                key="new"
                                startContent={<Profile />}
                                onClick={handleOpenProfile}
                            >
                                Profile
                            </DropdownItem>
                            <DropdownItem
                                key="copy"
                                onClick={handleLogout}
                                startContent={<Logout />}
                            >
                                Logout
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                </>
            }

            <ProfileWithoutSubscription
                isOpen={openProfile}
                onClose={handleCloseProfile}
                openPremium={handleOpenPremium}
                openEdit={handleOpenEdit}
            />

            <SubscriptionModel isOpen={openPremium} onClose={handleClosePremium} />


            <EditModal
                isOpen={openEdit}
                onClose={handleCloseEdit}
                resetPass={handleOpenResetPass}
            />

            <Reset isOpen={openResetPass} onClose={handleCloseResetPass} />

        </>
    );
}
