import React from "react";
import ReactPaginate from "react-paginate";

function Pagination({ paginationData, onPageChange }) {
  const handlePageClick = (page) => {
    onPageChange(page.selected + 1);
  };

  if (paginationData?.totalPages <= 1) {
    return null;
  }

  return (
    <div className="paginate-wrapper">
      <ReactPaginate
        initialPage={paginationData?.page - 1}
        breakLabel="..."
        nextLabel={paginationData?.page < paginationData?.totalPages ? ">" : null}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={paginationData?.totalPages}
        previousLabel={paginationData?.page > 1 ? "<" : null}
        renderOnZeroPageCount={null}
        disableInitialCallback={true}
      />
    </div>
  );
}

export default Pagination;