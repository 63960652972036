"use client";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import FormField from "../FormFields/FormField";
import toast from "react-hot-toast";
import { handleFormikChange, handleFormikSubmit } from "../../helpers/common";
import { updatePrompt } from "../../api/admin.api";
import FormFieldTextarea from "../FormFields/FormFieldTextarea";

const UpdatePromptForm = ({ model, onOpenChange }) => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    prompt: yup.string().required("Prompt is required"),
    model: yup.string().required("Name is required"),
  });

  const formObj = useFormik({
    initialValues: {
      prompt: model.prompt,
      model: model.model,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const loader = toast.loading("Please wait...");
      setIsLoading(true);
      updatePrompt(model._id, values)
        .then((res) => {
          toast.success(res?.message);
          formObj.resetForm();
          if (onOpenChange) {
            onOpenChange(false);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
          toast.dismiss(loader);
        });
    },
  });

  return (
    <form
      className="formbar"
      onSubmit={(e) => handleFormikSubmit(e, formObj)}
    >

      <FormField
        id="model"
        name="model"
        value={formObj.values.model}
        onChange={(e) => handleFormikChange(e, formObj)}
        showError={formObj.touched.model}
        errorText={formObj.errors.model}
        placeholder="Enter Model name"
      />

      <FormFieldTextarea
        id="prompt"
        name="prompt"
        value={formObj.values.prompt}
        onChange={(e) => handleFormikChange(e, formObj)}
        showError={formObj.touched.prompt}
        errorText={formObj.errors.prompt}
        placeholder="Enter Prompt"
      />

      <div className="theme-formbtn modal-buttons">
        <button
          className="btn btn-primary"
          type="submit"
          disabled={isLoading}
        >
          Update
        </button>
      </div>
    </form>
  );
};

export default UpdatePromptForm;