import { Outlet } from "react-router-dom";
import Header from "../components/Header";

const MainLayout = () => {
  return (
    <>
      {/* {loading ? (
        <>
          <div id="main-app-loader">
            <Loader />
          </div>
        </>
      ) : null} */}

      <Header />
      <Outlet />
    </>
  );
};

export default MainLayout;
