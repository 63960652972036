// import logo from './logo.svg';
import "./App.css";
import "./output.css";
import "./main.css";
import "./styles/dashboard.css";
import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import TitleDescription from "./page/Video/TitleDescription.js";
import History from "./page/Video/History.js";

import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./page/Home";
import MainLayout from "./components/Layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn } from "./redux/actions/auth.actions";
import Dashboard from "./page/Dashboard";
import AdminLayout from "./components/Layouts/AdminLayout";
import Prompts from "./page/Settings/Prompts";
import Contents from "./page/Admin/Contents";
import ContentDetails from "./page/Admin/ContentDetails";
import VideoEditor from "./page/Video/VideoEditor";

function AppRouter() {

  const { authenticate, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { hash } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.setAttribute('data-page', window?.location?.pathname);
  }, [navigate, authenticate, user, hash]);

  useEffect(() => {
    if (!authenticate) {
      dispatch(isUserLoggedIn());
    }
    if (!user) {
      dispatch(isUserLoggedIn());
    }
  }, [authenticate, user]);

  return (
    <>

      <Routes>

        {
          authenticate ? <>

            {
              user?.role === 'admin' ? <>

                <Route path="/admin" element={<AdminLayout />}>
                  <Route path="" element={<Dashboard />} />
                  <Route path="contents" element={<Contents />} />
                  <Route path="content/:slug" element={<ContentDetails />} />
                </Route>

                <Route path="/settings" element={<AdminLayout />}>
                  <Route path="prompts" element={<Prompts />} />
                </Route>

              </> : null
            }

          </> : <>

          </>
        }

        <Route path="/" element={<MainLayout />}>
          <Route path="" element={<Home />} />
          <Route path="/title" element={<TitleDescription />} />
          <Route path="/history" element={<History />} />
          <Route path="/video/:videoId" element={<VideoEditor />} />
        </Route>

        {/* <Route path="*" element={<Navigate to="/" replace={true} />} /> */}

      </Routes>

    </>
  );
}

export default AppRouter;
