import { Navigate } from "react-router-dom";
import { checkToken } from "../helpers/common";

const ProtectRoute = ({ element: Element, ...rest }) => {
  const tokenIsValid = checkToken();

  return tokenIsValid ? <Element /> : <Navigate to="/#login" replace />;
};

export default ProtectRoute;
