import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";

export default function ScriptDesc({ isOpen, onClose, scriptData }) {
  const { onOpen, onOpenChange } = useDisclosure();

  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      size="3xl"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      onClose={onClose}
    >
      <ModalContent>
        <>
          <ModalHeader className="modal-heading">Description</ModalHeader>
          <ModalBody className="text-white">
            <div className="grid description-box">
              <p>{scriptData?.description || "No Description Added"}</p>
            </div>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center"></ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
