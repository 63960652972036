import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    if (auth.authenticate) {
      navigate("/title");
    } else {
      navigate("#login");
    }
  };

  return (
    <section className="overflow-hidden">
      <div className="container login-banner  mx-auto flex items-center flex-col justify-center  gap-26 ">
        <div className="create-bubble">
          <h2 className="text-center text-white text-[22px] font-bold leading-[140%]">
            #Create
          </h2>
          <p className="text-[#DADADA] text-center font-red-rose text-[14px] font-normal leading-[140%]">
            Create any video with website name by giving title and description
          </p>
        </div>
        <div className="create-bubble-2">
          <h2 className="text-center text-white text-[22px] font-bold leading-[140%]">
            #Create
          </h2>
          <p className="text-[#DADADA] text-center font-red-rose text-[14px] font-normal leading-[140%]">
            Create any video with website name by giving title and description
          </p>
        </div>
        <div className="create-bubble-3">
          <h2 className="text-center text-white text-[22px] font-bold leading-[140%]">
            #Create
          </h2>
          <p className="text-[#DADADA] text-center font-red-rose text-[14px] font-normal leading-[140%]">
            Create any video with website name by giving title and description
          </p>
        </div>
        <h1 className="tag-line">Generate With NichoTube</h1>
        <p className="tag-subline">
          Website name will help you create a script and a video accordingly,
          press get <br></br> started now below.
        </p>
        <button className="startedbtn" onClick={handleGetStarted}>
          <span className="startedcontainer">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M6 12H18M18 12L13 7M18 12L13 17"
                  stroke="#000000"
                  stroke-width="0.648"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </span>
          <p className="text-white text-[16px] font-normal leading-[1.4]">
            Get Started
          </p>
        </button>
      </div>
    </section>
  );
}
