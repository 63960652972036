import React, { useEffect, useState } from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import { LockIcon } from "../Icons/LockIcon.jsx";
import { useGoogleLogin } from "@react-oauth/google";
import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Link,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
  Input,
  useDisclosure,
} from "@nextui-org/react";
import Joi from "joi-browser";
import {
  validateJOIFormField,
  validateJOIProperty,
} from "../helpers/common.js";
import { useDispatch } from "react-redux";
import { googleAuth, register } from "../redux/actions/auth.actions.js";

export default function RegisterModal({ isOpen, onClose, openLogin, openOtp }) {
  const dispatch = useDispatch();

  const { onOpen, onOpenChange } = useDisclosure();
  const [isVisible, setIsVisible] = React.useState(false);
  const [isVisibleRe, setIsVisibleRe] = React.useState(false);
  const toggleVisibilityRe = () => setIsVisibleRe(!isVisibleRe);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const [scrollBehavior, setScrollBehavior] = React.useState("outside");
  const [errors, setErrors] = useState({});
  const [termAccept, setTermAccept] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const schema = {
    first_name: Joi.string().required(),
    last_name: Joi.string().required(),
    phone_number: Joi.string().required(),
    email: Joi.string().email().required(),
    password: Joi.string().required(),
    confirm_password: Joi.string().required(),
  };

  useEffect(() => {
    setErrors({});
    setTermAccept(false);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const errorData = { ...errors };
    var errorMessage = validateJOIProperty(schema, e);
    if (errorMessage) {
      errorData[name] = errorMessage;
    } else {
      errorData[name] && delete errorData[name];
    }
    setErrors(errorData);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRegisterUser = () => {
    if (!termAccept) {
      setErrors((prev) => ({
        ...prev,
        accept_term: "Accept Terms To Continue",
      }));
      return false;
    }
    let error = validateJOIFormField(formData, schema);
    if (error) {
      setErrors(error);
      return;
    }
    if (formData?.password != formData?.confirm_password) {
      setErrors((prev) => ({
        ...prev,
        confirm_password: "Password does not match",
      }));
      return false;
    }

    dispatch(register(formData))
      .then((res) => {
        if (res.success) {
          openOtp();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // dispatch(login(formData));
  };

  const responseGoogle = async (authResult) => {
    try {
      if (authResult["code"]) {
        dispatch(googleAuth(authResult.code));
        onClose();
      } else {
        console.log(authResult);
        throw new Error(authResult);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: responseGoogle,
    flow: "auth-code",
  });

  return (
    <Modal
      scrollBehavior={scrollBehavior}
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      onClose={onClose}
    >
      <ModalContent>
        <>
          <ModalHeader className="modal-heading">Register</ModalHeader>
          <ModalBody className="text-white  gap-[24px]">
            <div className="mail-input flex gap-2 form-field">
              <div>
                <Input
                  isRequired
                  className="text-white"
                  classNames={{
                    label: "text-white !important",
                  }}
                  style={{
                    label: { color: "white !important" },
                  }}
                  radius="full"
                  type="text"
                  label="First Name"
                  placeholder="Enter First Name"
                  labelPlacement="outside"
                  variant="bordered"
                  name="first_name"
                  onChange={handleInputChange}
                />
                {errors.first_name && (
                  <p className="validation_error">{errors.first_name}</p>
                )}
              </div>

              <div>
                <Input
                  isRequired
                  className="text-white"
                  classNames={{
                    label: "text-white !important",
                  }}
                  style={{
                    label: { color: "white !important" },
                  }}
                  radius="full"
                  type="text"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  labelPlacement="outside"
                  variant="bordered"
                  name="last_name"
                  onChange={handleInputChange}
                />
                {errors.last_name && (
                  <p className="validation_error">{errors.last_name}</p>
                )}
              </div>
            </div>
            <div className="mail-input form-field">
              <Input
                isRequired
                className="text-white"
                classNames={{
                  label: "text-white !important",
                }}
                style={{
                  label: { color: "white !important" },
                }}
                radius="full"
                type="email"
                label="Email"
                placeholder="Enter Email"
                labelPlacement="outside"
                variant="bordered"
                endContent={
                  <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                }
                name="email"
                onChange={handleInputChange}
              />
              {errors.email && (
                <p className="validation_error">{errors.email}</p>
              )}
            </div>
            <div className="password-input form-field">
              <Input
                isRequired
                type={"text"}
                radius="full"
                label="Phone Number"
                placeholder="Enter Phone Number"
                labelPlacement="outside"
                variant="bordered"
                name="phone_number"
                onChange={handleInputChange}
              />
              {errors.phone_number && (
                <p className="validation_error">{errors.phone_number}</p>
              )}
            </div>
            <div className="password-input form-field">
              <Input
                isRequired
                endContent={
                  <button
                    className="focus:outline-none text-white border-solid border-white"
                    type="button"
                    classNames={{
                      label: "text-white",
                    }}
                    onClick={toggleVisibility}
                    aria-label="toggle password visibility"
                  >
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                radius="full"
                label="Password"
                placeholder="Enter password"
                labelPlacement="outside"
                variant="bordered"
                name="password"
                onChange={handleInputChange}
              />
              {errors.password && (
                <p className="validation_error">{errors.password}</p>
              )}
            </div>

            <div className="password-input form-field">
              <Input
                isRequired
                endContent={
                  <button
                    className="focus:outline-none text-white border-solid border-white"
                    type="button"
                    classNames={{
                      label: "text-white",
                    }}
                    onClick={toggleVisibilityRe}
                    aria-label="toggle password visibility"
                  >
                    {isVisibleRe ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisibleRe ? "text" : "password"}
                radius="full"
                label="Re-Type Password*"
                placeholder="Enter password"
                labelPlacement="outside"
                variant="bordered"
                name="confirm_password"
                onChange={handleInputChange}
              />
              {errors.confirm_password && (
                <p className="validation_error">{errors.confirm_password}</p>
              )}
            </div>

            <div className="flex py-2 px-1 justify-between">
              <Checkbox
                classNames={{
                  label: "text-small text-white",
                }}
                defaultSelected={termAccept}
                onChange={(e) => {
                  setTermAccept(e.target.checked);
                  setErrors((prev) => ({ ...prev, accept_term: null }));
                }}
              >
                I have read and accept the specific registration conditions.
              </Checkbox>
              {errors.accept_term && (
                <p className="validation_error">{errors.accept_term}</p>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center">
            <Button
              className="modal-submit-btn"
              color="primary"
              onPress={handleRegisterUser}
            >
              Register Now
            </Button>
            <p className="register-text mt-5  text-lg ">
              Already have an account?
              <Link
                href={undefined}
                className="font-bold form-link-righter"
                onClick={openLogin}
              >
                Login
              </Link>
            </p>
            <p className="register-text text-lg">
              The fields marked with <span className="mandatory-mark">*</span>{" "}
              are mandatory
            </p>
            <div class="relative flex w-[25rem] overflow-hidden py-5 items-center">
              <div class="flex-grow border-t w-full border-[#3D3D3D]"></div>
              <span class="flex-shrink mx-4 text-gray-400">or</span>
              <div class="flex-grow border-t w-full border-[#3D3D3D]"></div>
            </div>

            <Button
              className="w-full flex login-with-google"
              color="primary"
              onPress={googleLogin}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <g clip-path="url(#clip0_134_4)">
                  <path
                    d="M9.05735 0.855259C6.45954 1.75646 4.2192 3.46697 2.66539 5.73554C1.11158 8.00412 0.326205 10.7112 0.424621 13.4591C0.523037 16.207 1.50006 18.851 3.21218 21.0026C4.92429 23.1542 7.28126 24.7 9.93688 25.4131C12.0898 25.9686 14.3455 25.993 16.51 25.4842C18.4708 25.0437 20.2836 24.1016 21.7709 22.7501C23.3189 21.3005 24.4425 19.4564 25.0209 17.416C25.6497 15.1972 25.7615 12.8639 25.348 10.5951H13.258V15.6103H20.2597C20.1198 16.4101 19.8199 17.1736 19.378 17.8549C18.9362 18.5361 18.3614 19.1213 17.6881 19.5753C16.8331 20.1408 15.8693 20.5214 14.8586 20.6924C13.8449 20.8809 12.8051 20.8809 11.7914 20.6924C10.764 20.48 9.79205 20.056 8.9375 19.4473C7.56468 18.4755 6.53388 17.0949 5.99219 15.5026C5.44135 13.8804 5.44135 12.1218 5.99219 10.4996C6.37778 9.36257 7.0152 8.32728 7.85688 7.47104C8.82008 6.47318 10.0395 5.75991 11.3814 5.40948C12.7233 5.05904 14.1358 5.08499 15.4639 5.48448C16.5014 5.80296 17.4502 6.35941 18.2345 7.10948C19.024 6.32406 19.8121 5.53661 20.5989 4.74713C21.0052 4.3226 21.448 3.91838 21.8481 3.4837C20.6508 2.36951 19.2455 1.50253 17.7125 0.932447C14.921 -0.0811677 11.8665 -0.108408 9.05735 0.855259Z"
                    fill="white"
                  />
                  <path
                    d="M9.05739 0.85527C11.8663 -0.109052 14.9208 -0.0825291 17.7125 0.930426C19.2458 1.50438 20.6505 2.37553 21.8461 3.49386C21.4399 3.92855 21.0113 4.3348 20.5969 4.7573C19.8088 5.54407 19.0213 6.32813 18.2346 7.10949C17.4502 6.35942 16.5014 5.80297 15.4639 5.48449C14.1363 5.0836 12.7238 5.05615 11.3816 5.40515C10.0393 5.75415 8.81915 6.46611 7.85489 7.46293C7.01321 8.31917 6.37578 9.35446 5.9902 10.4915L1.77942 7.23136C3.28662 4.2425 5.89625 1.95626 9.05739 0.85527Z"
                    fill="#E33629"
                  />
                  <path
                    d="M0.662148 10.4609C0.888472 9.33922 1.26422 8.25297 1.77934 7.2312L5.99012 10.4995C5.43927 12.1217 5.43927 13.8803 5.99012 15.5025C4.5872 16.5858 3.18361 17.6745 1.77934 18.7687C0.489797 16.2018 0.0965107 13.2772 0.662148 10.4609Z"
                    fill="#F8BD00"
                  />
                  <path
                    d="M13.258 10.593H25.348C25.7615 12.8618 25.6497 15.1952 25.021 17.414C24.4425 19.4543 23.3189 21.2984 21.771 22.748C20.412 21.6877 19.047 20.6355 17.6881 19.5752C18.3619 19.1208 18.9369 18.535 19.3788 17.853C19.8206 17.171 20.1203 16.4067 20.2597 15.6061H13.258C13.256 13.9365 13.258 12.2647 13.258 10.593Z"
                    fill="#587DBD"
                  />
                  <path
                    d="M1.77734 18.7687C3.18161 17.6854 4.58521 16.5966 5.98812 15.5024C6.53089 17.0953 7.56317 18.476 8.9375 19.4471C9.7947 20.053 10.7687 20.4736 11.7975 20.6821C12.8112 20.8706 13.851 20.8706 14.8647 20.6821C15.8754 20.511 16.8392 20.1305 17.6942 19.5649C19.0531 20.6253 20.4181 21.6774 21.777 22.7378C20.2899 24.09 18.4771 25.0328 16.5161 25.4738C14.3516 25.9827 12.0959 25.9583 9.94297 25.4028C8.24018 24.9481 6.64965 24.1466 5.27109 23.0485C3.81197 21.8901 2.62022 20.4302 1.77734 18.7687Z"
                    fill="#319F43"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_134_4">
                    <rect width="26" height="26" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Continue with Google
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
