import { auth_constants } from "../actions/constants";

const initState = {
  token: null,
  user: {
    _id: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    activated: false,
  },
  authenticate: false,
  authenticating: false,
  loading: false,
  error: null,
  message: "",
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case auth_constants.PROFILE_REQUEST:
      state = {
        ...state,
        loading: true,
        authenticating: true,
        error: null,
      };
      break;
    case auth_constants.PROFILE_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        authenticate: true,
        loading: false,
        authenticating: false,
      };
      break;
    case auth_constants.PROFILE_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
        authenticating: false,
      };
      break;
    case auth_constants.LOGOUT_SUCCESS:
      state = {
        ...initState,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default authReducer;
