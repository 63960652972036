import React from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import { LockIcon } from "../Icons/LockIcon.jsx";
import { Diamond } from "../Icons/Diamond.jsx";
import { EditIcon } from "../Icons/EditIcon.jsx";

import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Link,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
  Input,
  useDisclosure,
  Divider,
} from "@nextui-org/react";

export default function SubscriptionModel({ isOpen, onClose }) {
  const { onOpen, onOpenChange } = useDisclosure();
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="xl"
      onClose={onClose}
      placement="top-center"
    >
      <ModalContent>
        <>
          <ModalHeader className="flex items-center text-white flex-col text-center gap-1">
            <h6 className="text-[16px ]">Buy Premium </h6>
          </ModalHeader>
          <ModalBody className="text-white relative">
            <div className="md:flex gap-4">
              <div className="border md:w-1/2 flex items-center flex-col p-4 rounded-xl border-[#fff]">
                <p className="text-[18px]">
                  <span className="dollar-text relative text-[64px]">8/</span>{" "}
                  month
                </p>
                <p className="text-[26px] font-bold">Monthly</p>
                <p className="text-[18px] text-[#A1A1A1] text-center">
                  Pay month to month fee of $8
                </p>
              </div>
              <div className="border md:w-1/2 flex items-center flex-col p-4 rounded-xl border-[#fff]">
                <p className="text-[18px]">
                  <span className="dollar-text relative text-[64px]">5/</span>{" "}
                  month
                </p>
                <p className="text-[26px] font-bold">Yearly</p>
                <p className="text-[18px] text-center text-[#A1A1A1]">
                  Pay $60 upfront and save up to{" "}
                  <span className="highlighted-text">15%</span>
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center">
            <Button color="" className="modal-submit-btn">
              Join Premium
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
