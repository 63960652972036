// import logo from './logo.svg';
import "./App.css";
import "./output.css";
import "./main.css";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LoginModal from "./components/LoginModal.js";
import Header from "./components/Header.js";
import RegisterModal from "./components/RegisterModal.js";
import EnterOtp from "./components/EnterOtp.js";
import ResetPassword from "./components/ResetPassword.js";
import Reset from "./components/Reset.js";
import DemoModal from "./components/DemoModal.js";
import ProfileWithoutSubscription from "./components/ProfileWithoutSubscription.js";
import SubscriptionModel from "./components/SubscriptionModel.js";
import ProfileWithSubscription from "./components/ProfileWithSubscription.js";
import EditModal from "./components/EditModal.js";
import LoginModal from "./components/LoginModal.js";
import TitleDescription from "./components/TitleDescription.js";
import ScriptGenerated from "./components/ScriptGenerated.js";
import VoiceSettingModal from "./components/VoiceSettingModal.js";
import CaptionSetting from "./components/CaptionSetting.js";
import History from "./components/History.js";
import VideoGenerated from "./components/VideoGenerated.js";

import {
  Link,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Checkbox,
  Input,
} from "@nextui-org/react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import MainLayout from "./layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn } from "./redux/actions/auth.actions";
import ProtectRoute from "./components/ProtectRoute";
import { haveValue } from "./helpers/common";

function App() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isVisible, setIsVisible] = React.useState(false);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
    if (!auth.user) {
      dispatch(isUserLoggedIn());
    }
  }, [auth.authenticate,auth.user]);
  // useEffect(() => {
  //   if(haveValue())
  // },[])

  const toggleVisibility = () => setIsVisible(!isVisible);
  // const LoginModal = () => {
  //   const [isModalOpen, setIsModalOpen] = useState(false);

  //   const LoginModal = () => {
  //     setIsModalOpen(true);
  //   };

  //   const handleCloseModal = () => {
  //     setIsModalOpen(false);
  //   };
  // }

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="" element={<Home />} />
          <Route
            path="/title"
            element={<ProtectRoute element={TitleDescription} />}
          />
          <Route
            path="/script"
            element={<ProtectRoute element={ScriptGenerated} />}
          />
          <Route path="/history" element={<ProtectRoute element={History} />} />
          <Route
            path="/video/generated/:videoId"
            element={<ProtectRoute element={VideoGenerated} />}
          />
        </Route>
      </Routes>

      {/* <section className="overflow-hidden">
        <div className="container login-banner  mx-auto flex items-center flex-col justify-center  gap-26 ">
          <div className="create-bubble">
            <h2 className="text-center text-white text-[22px] font-bold leading-[140%]">
              #Create
            </h2>
            <p className="text-[#DADADA] text-center font-red-rose text-[14px] font-normal leading-[140%]">
              Create any video with website name by giving title and description
            </p>
          </div>
          <div className="create-bubble-2">
            <h2 className="text-center text-white text-[22px] font-bold leading-[140%]">
              #Create
            </h2>
            <p className="text-[#DADADA] text-center font-red-rose text-[14px] font-normal leading-[140%]">
              Create any video with website name by giving title and description
            </p>
          </div>
          <div className="create-bubble-3">
            <h2 className="text-center text-white text-[22px] font-bold leading-[140%]">
              #Create
            </h2>
            <p className="text-[#DADADA] text-center font-red-rose text-[14px] font-normal leading-[140%]">
              Create any video with website name by giving title and description
            </p>
          </div>
          <h1 className="tag-line">Generate With ‘Website Name’</h1>
          <p className="tag-subline">
            Website name will help you create a script and a video accordingly,
            press get <br></br> started now below.
          </p>
          <button className="startedbtn">
            <span className="startedcontainer">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M6 12H18M18 12L13 7M18 12L13 17"
                    stroke="#000000"
                    stroke-width="0.648"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </span>
            <p className="text-white text-[22px] font-normal leading-[1.4]">
              Get Started
            </p>
          </button>
        </div> */}
      {/* <LoginModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}></LoginModal> */}
      {/* <RegisterModal  isOpen={isModalOpen}
          onClose={handleCloseModal}></RegisterModal> */}
      {/* <EnterOtp  isOpen={isModalOpen}
          onClose={handleCloseModal}></EnterOtp> */}
      {/* <ResetPassword  isOpen={isModalOpen}
          onClose={handleCloseModal}></ResetPassword> */}
      {/* <Reset isOpen={isModalOpen}
          onClose={handleCloseModal}></Reset> */}
      {/* <DemoModal isOpen={isModalOpen}
          onClose={handleCloseModal}></DemoModal> */}
      {/* <ProfileWithoutSubscription isOpen={true}
          onClose={() => {console.log("close")}}></ProfileWithoutSubscription> */}
      {/* <SubscriptionModel isOpen={true} onClose={() => {}}></SubscriptionModel> */}
      {/* <ProfileWithSubscription
        isOpen={true}
        onClose={() => {
          console.log("close");
        }}
      ></ProfileWithSubscription> */}
      {/* <EditModal isOpen={isModalOpen} onClose={handleCloseModal}></EditModal> */}
      {/* <VoiceSettingModal isOpen={isModalOpen} onClose={handleCloseModal}></VoiceSettingModal> */}
      {/* <CaptionSetting isOpen={isModalOpen} onClose={handleCloseModal}></CaptionSetting> */}
      {/* </section> */}
      {/* <TitleDescription></TitleDescription> */}
      {/* <ScriptGenerated></ScriptGenerated> */}
      {/* <History ></History> */}
      {/* <VideoGenerated></VideoGenerated> */}
    </>
  );
}

export default App;
