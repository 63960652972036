import React from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import { LockIcon } from "../Icons/LockIcon.jsx";

import { EyeFilledIcon } from "../Icons/EyeFilledIcon.jsx";
import { EyeSlashFilledIcon } from "../Icons/EyeSlashFilledIcon.jsx";

import {
  Link,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
  Input,
  useDisclosure,
  Divider,
  RadioGroup,
  Radio,
} from "@nextui-org/react";

export default function CaptionSetting({
  isOpen,
  onClose,
  captionSettings,
  changeCaption,
}) {
  const { onOpen, onOpenChange } = useDisclosure();
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      size="3xl"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      onClose={onClose}
    >
      <ModalContent>
        <>
          <ModalHeader className="modal-heading">Caption Settings</ModalHeader>
          <ModalBody className="text-white">
            <div className="flex flex-col gap-4">
              <h6 className="caption-modal-label">Text color</h6>
              <div className="flex gap-3 colors-options">
                <span
                  className={`${
                    captionSettings.captionTextColor == "#FF0000" &&
                    "active_result"
                  } rounded-full bg-[#FF0000] w-[34px] h-[34px]`}
                  onClick={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionTextColor: "#FF0000",
                    })
                  }
                ></span>
                <span
                  className={`${
                    captionSettings.captionTextColor == "#319F43" &&
                    "active_result"
                  } rounded-full bg-[#319F43] w-[34px] h-[34px]`}
                  onClick={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionTextColor: "#319F43",
                    })
                  }
                ></span>
                <span
                  className={`${
                    captionSettings.captionTextColor == "#00A3FF" &&
                    "active_result"
                  } rounded-full bg-[#00A3FF] w-[34px] h-[34px]`}
                  onClick={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionTextColor: "#00A3FF",
                    })
                  }
                ></span>
                <span
                  className={`${
                    captionSettings.captionTextColor == "#0D0D0D" &&
                    "active_result"
                  } rounded-full bg-[#0D0D0D] w-[34px] h-[34px]`}
                  onClick={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionTextColor: "#0D0D0D",
                    })
                  }
                ></span>
                <span
                  className={`${
                    captionSettings.captionTextColor == "#FFFFFF" &&
                    "active_result"
                  } rounded-full bg-[#FFFFFF] w-[34px] h-[34px]`}
                  onClick={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionTextColor: "#FFFFFF",
                    })
                  }
                ></span>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h6 className="caption-modal-label">Background color</h6>
              <div className="flex gap-3 colors-options">
                <span
                  className={`${
                    captionSettings.captionBgColor == "WHITE" &&
                    "active_result"
                  } rounded-full bg-[#FFFFFF] w-[34px] h-[34px]`}
                  onClick={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionBgColor: "WHITE",
                    })
                  }
                ></span>
                <span
                  className={`${
                    captionSettings.captionBgColor == "BLACK" &&
                    "active_result"
                  } rounded-full bg-[#0D0D0D] w-[34px] h-[34px]`}
                  onClick={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionBgColor: "BLACK",
                    })
                  }
                ></span>
                <span
                  className={`${
                    captionSettings.captionBgColor == "AUTO" &&
                    "active_result"
                  } rounded-full auto-bg w-[34px] h-[34px]`}
                  onClick={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionBgColor: "AUTO",
                    })
                  }
                >Auto</span>
                <span
                  className={`${
                    captionSettings.captionBgColor == "NONE" &&
                    "active_result"
                  } rounded-full none-bg w-[34px] h-[34px]`}
                  onClick={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionBgColor: "NONE",
                    })
                  }
                >None</span>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <h6 className="caption-modal-label">Text-Size</h6>
              <div className="flex gap-3 radio-group">
                <RadioGroup
                  orientation="horizontal"
                  value={captionSettings.captionTextSize}
                  onChange={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionTextSize: e.target.value,
                    })
                  }
                >
                  <Radio value="12">12px</Radio>
                  <Radio value="14">14px</Radio>
                  <Radio value="16">16px </Radio>
                  <Radio value="18">18px </Radio>
                  <Radio value="20">20px </Radio>
                </RadioGroup>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <h6 className="caption-modal-label">Position</h6>
              <div className="flex gap-3 radio-group">
                <RadioGroup
                  orientation="horizontal"
                  value={captionSettings.captionPosition}
                  onChange={(e) =>
                    changeCaption({
                      ...captionSettings,
                      captionPosition: e.target.value,
                    })
                  }
                >
                  <Radio value={"BOTTOM"}>Bottom</Radio>
                  <Radio value={"CENTER"}>Middle</Radio>
                  <Radio value={"TOP"}>Top </Radio>
                </RadioGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center"></ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
