import React from "react";
import BaseModal from "../Modals/BaseModal";

const LogDetailsModal = ({ isOpen, setIsOpen, model }) => {

  return (
    <BaseModal isModalOpen={isOpen} setIsModalOpen={setIsOpen} title={`Log Details`}>
      <div className="overflow-x-auto w-full">
          <textarea className="w-full bg-base-200 text-base-content p-3 form-control" rows={20}>{JSON.stringify(model?.data)}</textarea>
      </div>
    </BaseModal>
  );
};

export default LogDetailsModal;