import React, { useEffect, useState } from "react";
import { MailIcon } from "../Icons/MailIcon.jsx";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  useDisclosure,
} from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import {
  validateJOIFormField,
  validateJOIProperty,
} from "../helpers/common.js";
import Joi from "joi-browser";
import { updateUser } from "../redux/actions/auth.actions.js";
import { toast } from "react-toastify";

export default function EditModal({ isOpen, onClose, resetPass }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const user = auth.user;
  const { onOpen, onOpenChange } = useDisclosure();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
  });

  const schema = {
    first_name: Joi.string().required(),
    last_name: Joi.string().required(),
    phone_number: Joi.string().required(),
    email: Joi.string().email().required(),
  };

  useEffect(() => {
    setFormData({
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      phone_number: user?.phone_number || "",
      email: user?.email || "",
    });
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const errorData = { ...errors };
    var errorMessage = validateJOIProperty(schema, e);
    if (errorMessage) {
      errorData[name] = errorMessage;
    } else {
      errorData[name] && delete errorData[name];
    }
    setErrors(errorData);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitForm = () => {
    let error = validateJOIFormField(formData, schema);
    if (error) {
      setErrors(error);
      return;
    }
    dispatch(updateUser(formData, user?._id))
      .then((res) => {
        if (res.success) {
          toast.success("Profile Updated");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [scrollBehavior, setScrollBehavior] = React.useState("outside");

  return (
    <Modal
      scrollBehavior={scrollBehavior}
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      onClose={onClose}
    >
      <ModalContent>
        <>
          <ModalHeader className="flex text-4xl text-white flex-col text-center gap-1">
            <p className="modal-heading">Edit Profile</p>
          </ModalHeader>
          <ModalBody className="text-white">
            <div className="mail-input form-field flex gap-2 ">
              <Input
                className="text-white"
                classNames={{
                  label: "text-white !important",
                }}
                style={{
                  label: { color: "white !important" },
                }}
                radius="full"
                type="text"
                label="First Name"
                placeholder="First Name"
                labelPlacement="outside"
                variant="bordered"
                value={formData.first_name}
                name="first_name"
                onChange={handleInputChange}
              />
              {errors.first_name && (
                <p className="validation_error">{errors.first_name}</p>
              )}
              <Input
                className="text-white"
                classNames={{
                  label: "text-white !important",
                }}
                style={{
                  label: { color: "white !important" },
                }}
                radius="full"
                type="text"
                label="Last Name"
                placeholder="Enter Last Name"
                labelPlacement="outside"
                variant="bordered"
                value={formData.last_name}
                name="last_name"
                onChange={handleInputChange}
              />
              {errors.last_name && (
                <p className="validation_error">{errors.last_name}</p>
              )}
            </div>
            <div className="mail-input form-field">
              <Input
                className="text-white"
                classNames={{
                  label: "text-white !important",
                }}
                style={{
                  label: { color: "white !important" },
                }}
                radius="full"
                type="email"
                label="Email"
                placeholder="Enter Email"
                labelPlacement="outside"
                variant="bordered"
                endContent={
                  <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                }
                value={formData.email}
                name="email"
                onChange={handleInputChange}
              />
              {errors.email && (
                <p className="validation_error">{errors.email}</p>
              )}
            </div>
            <div className="password-input mail-input form-field">
              <Input
                endContent={
                  <button
                    className="focus:outline-none text-white border-solid border-white"
                    type="button"
                    classNames={{
                      label: "text-white",
                    }}
                    aria-label="toggle password visibility"
                  ></button>
                }
                type={"text"}
                radius="full"
                label="Phone Number"
                placeholder="Enter Phone Number"
                labelPlacement="outside"
                variant="bordered"
                value={formData.phone_number}
                name="phone_number"
                onChange={handleInputChange}
              />
              {errors.phone_number && (
                <p className="validation_error">{errors.phone_number}</p>
              )}
            </div>
            <p className="reset-password-link" onClick={resetPass}>
              <span className="">Reset password</span>
            </p>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center">
            <Button
              className="modal-submit-btn"
              color="primary"
              onPress={handleSubmitForm}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
