import React from "react";
import { Diamond } from "../Icons/Diamond.jsx";
import { EditIcon } from "../Icons/EditIcon.jsx";

import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/react";
import { useSelector } from "react-redux";

export default function ProfileWithoutSubscription({
  isOpen,
  onClose,
  openPremium,
  openEdit,
}) {
  const { onOpen, onOpenChange } = useDisclosure();
  const auth = useSelector((state) => state.auth);
  const user = auth.user

  return (
    <Modal
      className="rounded-3xl bg-[#0D0D0D]"
      backdrop="opaque"
      classNames={{
        backdrop:
          "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
      }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="xl"
      onClose={onClose}
      placement="top-center"
    >
      <ModalContent>
        <>
          <ModalHeader className="flex items-center text-white flex-col text-center gap-1">
            <div className="profile-name-letter">{user?.first_name && user?.first_name.charAt(0).toUpperCase()}{user?.first_name && user?.last_name.charAt(0).toUpperCase()}</div>
            <h5 className="text-4xl">{user?.first_name || ""} {user?.last_name || ""}</h5>
          </ModalHeader>
          <ModalBody className="text-white relative">
            {
              auth.user?.role != "admin" ? <>
                <Button
                  color="warning"
                  className="text-white font-bold rounded-3xl h-12 "
                  startContent={<Diamond />}
                  onClick={openPremium}
                >
                  Join Premium
                </Button>
              </> : null
            }
            <div className=" ">
              <div className="  bg-neutral-800 rounded-[40px] py-4 px-4 md:px-6">
                <div className=" flex justify-between items-center py-2">
                  <div className="flex gap-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M14.4231 17.9998C13.6387 17.9998 12.5367 17.7162 10.8867 16.7945C8.88015 15.6695 7.32813 14.6308 5.33246 12.6408C3.40831 10.7182 2.47196 9.47345 1.16147 7.08922C-0.319011 4.39722 -0.0666378 2.98614 0.215473 2.38305C0.551435 1.66224 1.04734 1.23112 1.68832 0.803208C2.05239 0.56472 2.43767 0.360281 2.83927 0.192487C2.87946 0.17521 2.91683 0.158736 2.95018 0.14387C3.14911 0.0542707 3.45051 -0.0811327 3.83228 0.0635119C4.08707 0.159138 4.31453 0.35481 4.67058 0.706377C5.40077 1.42639 6.39861 3.02993 6.76672 3.81744C7.01387 4.34821 7.17743 4.69857 7.17783 5.09152C7.17783 5.55157 6.94636 5.90635 6.66545 6.28926C6.61281 6.36118 6.56056 6.42988 6.50993 6.49658C6.20411 6.89837 6.137 7.01449 6.1812 7.22181C6.27082 7.63847 6.93912 8.8788 8.03743 9.97448C9.13573 11.0702 10.3405 11.6962 10.7589 11.7853C10.9751 11.8316 11.0936 11.7616 11.5084 11.445C11.5678 11.3996 11.6289 11.3526 11.6928 11.3056C12.1212 10.987 12.4596 10.7616 12.9089 10.7616H12.9113C13.3023 10.7616 13.6371 10.9311 14.1916 11.2108C14.915 11.5756 16.5671 12.5604 17.2916 13.2913C17.6441 13.6464 17.8406 13.8731 17.9366 14.1274C18.0813 14.5103 17.9451 14.8104 17.8563 15.0113C17.8414 15.0447 17.8249 15.0812 17.8076 15.1218C17.6385 15.5226 17.4328 15.907 17.1932 16.2701C16.766 16.909 16.3332 17.4036 15.6106 17.7399C15.2396 17.9154 14.8335 18.0042 14.4231 17.9998Z"
                        fill="white"
                      />
                    </svg>
                    <div className="  font-normal text-[#d1d1d1] text-base tracking-[0] leading-[22.4px] whitespace-nowrap">
                      Phone Number
                    </div>
                  </div>
                  <div className="    font-normal text-[#ffffff] text-lg text-right tracking-[0] leading-[25.2px] whitespace-nowrap">
                    {user?.phone_number || ""}
                  </div>
                </div>
                <div className=" flex justify-between items-center py-2">
                  <div className="flex gap-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="18"
                      viewBox="0 0 24 18"
                      fill="none"
                    >
                      <path
                        d="M21 0H3C2.20461 0.000812161 1.44204 0.302777 0.879619 0.839637C0.317195 1.3765 0.000850835 2.1044 0 2.86364V15.1364C0.000850835 15.8956 0.317195 16.6235 0.879619 17.1604C1.44204 17.6972 2.20461 17.9992 3 18H21C21.7954 17.9992 22.558 17.6972 23.1204 17.1604C23.6828 16.6235 23.9991 15.8956 24 15.1364V2.86364C23.9991 2.1044 23.6828 1.3765 23.1204 0.839637C22.558 0.302777 21.7954 0.000812161 21 0ZM20.2404 4.73676L12.5261 10.464C12.3756 10.5757 12.1905 10.6363 12 10.6363C11.8095 10.6363 11.6244 10.5757 11.4739 10.464L3.75964 4.73676C3.66901 4.67143 3.59288 4.58955 3.53568 4.49589C3.47848 4.40223 3.44135 4.29865 3.42646 4.19117C3.41156 4.08369 3.41918 3.97446 3.44889 3.86982C3.4786 3.76518 3.5298 3.66722 3.59952 3.58163C3.66923 3.49604 3.75607 3.42454 3.85499 3.37126C3.95391 3.31799 4.06293 3.28401 4.17572 3.2713C4.28852 3.25859 4.40283 3.26741 4.51203 3.29724C4.62122 3.32707 4.72311 3.37731 4.81179 3.44506L12 8.78165L19.1882 3.44506C19.368 3.31549 19.5939 3.25866 19.8172 3.28686C20.0405 3.31507 20.2432 3.42603 20.3814 3.59577C20.5197 3.7655 20.5824 3.98036 20.556 4.19388C20.5296 4.4074 20.4162 4.60242 20.2404 4.73676Z"
                        fill="white"
                      />
                    </svg>
                    <div className="  font-normal text-[#d1d1d1] text-base tracking-[0] leading-[22.4px] whitespace-nowrap">
                      Email
                    </div>
                  </div>
                  <div className="font-normal text-[#ffffff] text-lg text-right tracking-[0] leading-[25.2px] whitespace-nowrap">
                    {user?.email || ""}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="flex-col justify-self-center items-center">
            <Button
              color=""
              className="text-white bg-transparent font-bold border-white border w-full rounded-3xl h-12 "
              startContent={<EditIcon />}
              onClick={openEdit}
            >
              Edit Profile
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
}
