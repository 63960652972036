"use client";
import { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody
} from "@nextui-org/react";

const BaseModal = ({
  isModalOpen,
  setIsModalOpen,
  children,
  title = "Modal Title",
}) => {

  const [scrollBehavior, setScrollBehavior] = useState("outside");

  return (
    <>
      <Modal
        size="2xl"
        scrollBehavior={scrollBehavior}
        className="rounded-3xl bg-[#0D0D0D] base-modal"
        backdrop="opaque"
        classNames={{
          wrapper: 'bade-modal-wrapper',
          base: 'bade-modal-base',
          header: 'bade-modal-header',
          body: 'bade-modal-body',
          backdrop:
            "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
        }}
        isOpen={isModalOpen}
        onOpenChange={setIsModalOpen}
        placement="top-center"
        onClose={setIsModalOpen}
      >
        <ModalContent>
          <ModalHeader className="flex text-4xl text-white flex-col text-center gap-1">
            <p className="modal-heading">{title}</p>
          </ModalHeader>
          <ModalBody className="text-white mb-5">
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BaseModal;