import { Grid } from "react-loader-spinner";

const GenerationLoader = ({loadingText}) => {
  return (
    <div className="global-loader">
      <div className="child-loader">
        <Grid
          height="80"
          width="80"
          color="#FFFFFF"
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <p className="text-white global-loder-txt">{loadingText}</p>
      </div>
    </div>
  );
};

export default GenerationLoader;
