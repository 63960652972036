import { Link, useNavigate, useParams } from "react-router-dom";
import { getContentDetails } from "../../api/admin.api";
import { useEffect, useState } from "react";
import { camelToTitleCase, haveValue, nl2br, printDateTime, statusBadgeClass } from "../../helpers/common";
import SegmentList from "../../components/Content/SegmentList";
import LogList from "../../components/Content/LogList";

export default function ContentDetails() {

    const navigate = useNavigate();
    const { slug } = useParams();

    const [content, setContent] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const loadData = () => {
        setIsLoading(true);
        getContentDetails(slug, {})
            .then((res) => {
                setContent(res?.data);
            })
            .catch((err) => {
                console.log(err);
                navigate('/admin/contents');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadData();
    }, [slug]);

    return (
        <>
            {
                isLoading ? <>
                    <div className="flex w-full flex-col gap-4">
                        <div className="skeleton h-32 w-full"></div>
                        <div className="skeleton h-4 w-full"></div>
                    </div>
                </> : <>

                    <h1 className="text-3xl">Title:- {content?.title}</h1>
                    <p className="my-2 md:flex gap-2">
                        <span className={statusBadgeClass(content?.status)}>Status: {camelToTitleCase(content?.status)}</span>
                        <span className="badge badge-secondary">Created At: {printDateTime(content?.created_at)}</span>
                        <span className="badge badge-secondary">Updated At: {printDateTime(content?.updated_at)}</span>
                    </p>

                    <div className="collapse collapse-arrow bg-base-200 my-4">
                        <input type="radio" name="my-accordion-2" defaultChecked />
                        <div className="collapse-title text-xl font-medium">Description</div>
                        <div className="collapse-content">
                            {
                                haveValue(content?.description) ? <>
                                    <div dangerouslySetInnerHTML={{ __html: nl2br(content.description) }}></div>
                                </> : <>
                                    <p className="text-center p-5 lighttxt">No description added</p>
                                </>
                            }
                        </div>
                    </div>

                    <div className="collapse collapse-arrow bg-base-200 my-4">
                        <input type="radio" name="my-accordion-2" />
                        <div className="collapse-title text-xl font-medium">Script</div>
                        <div className="collapse-content">
                            {
                                haveValue(content?.script) ? <>
                                    <div dangerouslySetInnerHTML={{ __html: nl2br(content.script) }}></div>
                                </> : <>
                                    <p className="text-center p-5 lighttxt">No generated yet</p>
                                </>
                            }
                        </div>
                    </div>

                    <div className="collapse collapse-arrow bg-base-200 my-4">
                        <input type="radio" name="my-accordion-2" />
                        <div className="collapse-title text-xl font-medium">Segments</div>
                        <div className="collapse-content">
                            <SegmentList contentId={content?._id} />
                        </div>
                    </div>

                    <div className="collapse collapse-arrow bg-base-200 my-4">
                        <input type="radio" name="my-accordion-2" />
                        <div className="collapse-title text-xl font-medium">Logs</div>
                        <div className="collapse-content">
                            <LogList contentId={content?._id} />
                        </div>
                    </div>

                </>
            }
        </>
    )
}