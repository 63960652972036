"use client";

import { useState, useEffect } from "react";
import { camelToTitleCase, initialPaginationData, printDateTime } from "../../helpers/common";
import { getPromptsList } from "../../api/admin.api";
import Pagination from "../Common/Pagination";
import UpdatePromptModal from "./UpdatePromptModal";

function PromptsList() {

    const [page, setPage] = useState(1);
    const [paginationData, setPaginationData] = useState(initialPaginationData());
    const [isLoading, setIsLoading] = useState(true);

    const [isEditModelOpen, setIsEditModelOpen] = useState(false);
    const [editModel, setEditModel] = useState({});

    const loadData = (showLoading = true) => {
        if (showLoading) {
            setIsLoading(true);
        }

        const query = {
            page,
            limit: 10
        };

        query['limit'] = 1000;

        getPromptsList(query)
            .then((res) => {
                const content = res?.data;
                setPaginationData(content);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });

    };

    const handlePageChange = (newPageNo) => {
        setPage(newPageNo);
    };

    const handleEdit = (item) => {
        setEditModel(item);
        setIsEditModelOpen(true);
    }

    const handleEditModalClose = (nVal) => {
        setIsEditModelOpen(nVal);
        loadData();
    }

    useEffect(() => {
        loadData();
    }, [page]);

    return (
        <>
            <div className="card bg-base-200 w-100 conent-card-body-parent my-5">
                <div className="card-body conent-card-body">
                    <div className="overflow-x-auto w-full">
                        <table className="table table-zebra w-full">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Prompt</th>
                                    <th>Model</th>
                                    <th>Updated At</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    isLoading ? <>
                                        <tr>
                                            <td><span className="skeleton w-full h-6 block"></span></td>
                                            <td><span className="skeleton w-full h-6 block"></span></td>
                                            <td><span className="skeleton w-full h-6 block"></span></td>
                                            <td><span className="skeleton w-full h-6 block"></span></td>
                                            <td><span className="skeleton w-full h-6 block"></span></td>
                                        </tr>
                                    </> : <>
                                        {
                                            paginationData?.docs && paginationData?.docs.length > 0 ? <>
                                                {paginationData?.docs.map((item) => (
                                                    <tr key={item?._id}>
                                                        <td>{camelToTitleCase(item?.type)}</td>
                                                        <td>{item?.prompt}</td>
                                                        <td>{item?.model}</td>  
                                                        <td className="text-left">{printDateTime(item?.updated_at)}</td>
                                                        <td className="text-center p-0">
                                                            <button onClick={(e) => handleEdit(item)} className="btn btn-secondary normal-case btn-sm">
                                                                Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </> : <>
                                                <tr>
                                                    <td colSpan={5} className="text-center p-5 lighttxt">No Prompts Found</td>
                                                </tr>
                                            </>
                                        }
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                    {paginationData.totalDocs > 0 ? (
                        <Pagination
                            onPageChange={handlePageChange}
                            paginationData={paginationData}
                        />
                    ) : null}
                </div>
            </div>

            <UpdatePromptModal
                model={editModel}
                isOpen={isEditModelOpen}
                setIsOpen={handleEditModalClose}
            />

        </>
    );
}

export default PromptsList;