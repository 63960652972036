const KEY_PREFIX = "";

const getKeyName = (key) => {
    return KEY_PREFIX + key;
};

export const setLocalStorageItem = (key, val) => {
    localStorage.setItem(getKeyName(key), val);
};

export const getLocalStorageItem = (key, defaultValue= null) => {
    let val = localStorage.getItem(getKeyName(key));
    if (
        val === undefined ||
        val === "undefined" ||
        val === "" ||
        val === "null"
    ) {
        val = null;
    }
    if (val === null) {
        return defaultValue;
    }
    return val;
};

export const removeLocalStorageItem = (key) => {
    localStorage.removeItem(getKeyName(key));
};
