import React from "react";

export const Profile = (props) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="6.5" r="5.5" stroke="#696969" stroke-width="1.4" />
    <path
      d="M1.66666 23.0001V21.6667C1.66666 18.353 4.35295 15.6667 7.66666 15.6667L10.3333 15.6667C13.647 15.6667 16.3333 18.353 16.3333 21.6667V23.0001"
      stroke="#696969"
      stroke-width="1.4"
    />
  </svg>
);
