import ContentList from "../../components/Content/ContentList";

export default function Contents() {

    return (
        <>
            <h1 className="text-3xl">All Content</h1>
            <ContentList/>
        </>
    )  
}